import {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';

import {ESkeletonType} from 'types/App';
import {useAppSelector} from 'ducks/hooks';
import {sendSearchClickLog} from 'utils/logManager';
import useSearchRecommend from 'hooks/useSearchRecommend';
import {useOnce} from 'hooks/useOnce';
import useLogger from 'hooks/useLogger';
import useInterval from 'hooks/useInterval';
import {TSearchRecommend} from 'ducks/search/types';
import Skeleton from 'components/Skeleton';
import SearchRecommendationItem from 'components/search/SearchRecommendationItem';

import {ReactComponent as IconUp} from 'resource/images/@tmds_element/ico_arrow_up.svg';
import {ReactComponent as IconDown} from 'resource/images/@tmds_element/ico_arrow_down.svg';

import s from 'styles/components/search/SearchRecentRecommendation.module.scss';

const BANNER_DELAY = 1500;

type TRollingList = Array<
  TSearchRecommend & {
    order: number;
  }
>;

const SearchRecentRecommendation = () => {
  const {searchSessionKey} = useAppSelector((state) => ({
    searchSessionKey: state.userInfo.searchSessionKey,
  }));

  const {recommendList, subTitle, apiStatus, isExposeTime, radius, lonLat} = useSearchRecommend();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {sendSearchRecentRecommendLog} = useLogger();

  const isReady = apiStatus.loaded && recommendList.length > 2;
  const [trigger, setTrigger] = useState(false);
  const [rollingList, setRollingList] = useState<TRollingList>([]);

  const interval = useInterval(() => {
    setTrigger(true);
  }, BANNER_DELAY);

  const handleClickToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
    sendSearchClickLog('tap.near_poi_recommendation', {
      search_session_id: searchSessionKey,
      fold: isOpen,
    });

    if (isOpen) {
      interval.start();
    } else {
      interval.stop();
    }
  }, [isOpen]);

  useOnce(apiStatus.loaded && lonLat && recommendList.length > 2, () => {
    sendSearchRecentRecommendLog({
      search_radius: radius,
      list_num: recommendList?.length,
      coord: [lonLat?.lat, lonLat?.lon],
      pkey: recommendList.map((v) => v.pkey),
      cnt_type: isExposeTime ? 'tnow_num' : 'accumulated_num',
      obj_cnt: recommendList.map((v) => (isExposeTime ? v.headingForScore : v.headingForWeekScore)),
    });
  });

  useEffect(() => {
    if (isReady) {
      setRollingList((recommendList || []).map((v, i) => ({...v, order: i + 1})));
      interval.start();
    }

    return () => {
      interval.stop();
    };
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  if (!isOpen) {
    return (
      <div className={s.wrap}>
        <div className={s.main} onClick={handleClickToggle}>
          <Skeleton
            apiStatus={apiStatus}
            type={ESkeletonType.SEARCH_RECOMMENDATION}
            className={s.title}
          >
            <div className={s.sub}>주변맛집</div>

            <div className={s.rolling_wrap}>
              {rollingList.map((v, i) => (
                <div
                  key={i}
                  className={classNames(s.contents, {
                    [s.is_move]: trigger,
                  })}
                  onTransitionEnd={() => {
                    if (i === 0) {
                      setTrigger(false);

                      setRollingList((prev) => {
                        const first = prev.slice(0, 1);
                        const rest = prev.slice(1);

                        return [...rest, ...first];
                      });
                    }
                  }}
                >
                  <div className={s.recommendation}>
                    <div className={s.order}>{v.order}</div>
                    <div className={s.place}>{v.poiName}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className={s.icon}>
              <IconDown />
            </div>
          </Skeleton>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(s.wrap, {
        [s.open]: isOpen,
      })}
    >
      <div className={s.main}>
        <Skeleton
          apiStatus={apiStatus}
          type={ESkeletonType.SEARCH_RECOMMENDATION_OPEN}
          className={s.skeleton}
        >
          <div className={s.title}>
            <div className={s.sub}>주변 맛집</div>
            <div className={s.contents}>
              <div className={s.info}>{subTitle}</div>
              <div className={s.icon}>
                <IconUp onClick={handleClickToggle} />
              </div>
            </div>
          </div>

          <ul className={s.items}>
            {recommendList?.map((item, idx) => (
              <SearchRecommendationItem
                key={idx}
                data={item}
                order={idx + 1}
                isExposeTime={isExposeTime}
              />
            ))}
          </ul>
        </Skeleton>
      </div>
    </div>
  );
};

export default SearchRecentRecommendation;
