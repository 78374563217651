import {ERecentItemType} from 'types/Search';

import {ReactComponent as IconPublicRoute} from 'resource/images/ico_route_turn2.svg';
import {ReactComponent as IcoRouteTurnFilled} from 'resource/images/ico_route_turn.svg';
import {ReactComponent as IcoSearchBold} from 'resource/images/ico_search.svg';
import {ReactComponent as IcoHomeFilled} from 'resource/images/ico_home_solid.svg';
import {ReactComponent as IcoCompanyFilled} from 'resource/images/ico_company_solid.svg';
import {ReactComponent as IcoStarFilled} from 'resource/images/ico_star_solid.svg';
import {ReactComponent as IcoBusStopFilled} from 'resource/images/ico_bus.svg';
import {ReactComponent as IcoVehicleMetroFilled} from 'resource/images/ico_vehicle_metro_solid.svg';

import s from 'styles/components/search/SearchListIcon.module.scss';

type TProps = {
  srcType: ERecentItemType;
  className?: string;
};

const IMAGE_SRC_MAP: Record<ERecentItemType, JSX.Element> = {
  [ERecentItemType.POI]: <IcoRouteTurnFilled />,
  [ERecentItemType.QUERY]: <IcoSearchBold className={s.icon_query} />,
  [ERecentItemType.PUBLIC_POI]: <IconPublicRoute />,
  [ERecentItemType.HOME]: <IcoHomeFilled />,
  [ERecentItemType.OFFICE]: <IcoCompanyFilled />,
  [ERecentItemType.FAVORITE]: <IcoStarFilled className={s.icon_favorite} />,
  [ERecentItemType.FAVORITE_STATION]: <IcoStarFilled className={s.icon_favorite_transport} />,
  [ERecentItemType.FAVORITE_SUBWAY]: <IcoStarFilled className={s.icon_favorite_transport} />,
  [ERecentItemType.BUS_STATION]: <IcoBusStopFilled />,
  [ERecentItemType.SUBWAY]: <IcoVehicleMetroFilled />,
};

const SearchListIcon = ({srcType, className}: TProps): JSX.Element => {
  const IconElement = IMAGE_SRC_MAP[srcType];

  return (
    <i className={className} data-type={srcType}>
      {IconElement}
    </i>
  );
};

export default SearchListIcon;
