import axios from 'axios';
import {useCallback, useEffect, useMemo, useState} from 'react';
import qs from 'query-string';

import styles from 'styles/pages/DevDeepLinkPage.module.scss';
import classNames from 'classnames';
import {ESearchWebHost} from 'constant/Path';
import {generateUrl} from 'utils/url';
import {Paths} from 'constant/RoutePath';
import {EAppRequestMode} from 'types/App';
import {DEFAULT_VALID_CATEGORY_LINK} from 'constant/PlaceCategory';

export const generateShortUrl = (orgUrl) => {
  return axios
    .post('https://surl.tmobiapi.com/openapi/v1/urlshortener/shortener', {
      orgUrl,
      apiKey: 'skt-2f4a30f0-a443-4181-9008-2c0eeb95a058',
      routeCd: 'LG',
    })
    .then((res) => {
      // eslint-disable-next-line
      return res.data.shortUrl;
    });
};

const POI_ID_MAP = [
  {title: '티맵모빌리티', value: '10243691'},
  {title: 'SKT타워', value: '1128603'},
  {title: '여의도자이공인', value: '1935582'},
];

const POI_KEY_MAP = [
  {title: '티맵모빌리티', value: '1024369101'},
  {title: 'SKT타워', value: '112860300'},
  {title: '여의도자이공인', value: '1935582'},
];

const SEARCH_QUERIES = [
  'skt타워',
  '맛집',
  '티맵모빌리티',
  '명동2가 1-19',
  '우동 1388',
  '명절병원',
  '투표소',
  '사전투표소',
];

const getBridgeLink = (host, query) => {
  return qs.stringifyUrl({
    url: 'https://www.tmap.co.kr/tmap2/mobile/tmap.jsp',
    query: {
      scheme: 'tmap',
      host,
      ...query,
      tailParam: JSON.stringify(query.tailParam),
    },
  });
};

const getDeepLink = (host, query) => {
  return qs.stringifyUrl({
    url: `tmap://${host}`,
    query: {
      ...query,
      tailParam: JSON.stringify(query.tailParam),
    },
  });
};

const copyText = (text, enableConfirm = true) => {
  window.navigator.clipboard.writeText(text).then(() => {
    if (enableConfirm) {
      if (window.confirm('clipboard 에 복사되었습니다. 해당 경로로 바로 이동할까요?')) {
        window.location.href = text;
      }
    } else {
      window.alert('복사가 완료되었습니다.');
    }
  });
};

const LinkMap = ({meta, data, isBridge, tailParam = {}}) => {
  const preview = useMemo(() => {
    let query = {};

    if (typeof meta.paramKey === 'string') {
      query = {[meta.paramKey]: `[${meta.paramKey}]`};
    } else if (Array.isArray(meta.paramKey)) {
      query = meta.paramKey.reduce((prev, cur) => {
        return {...prev, [cur]: `[${cur}]`};
      }, {});
    }
    const deepLink = getDeepLink(meta.host, {...query, tailParam});
    const bridgeLink = getBridgeLink(meta.host, {...query, tailParam});

    return {deepLink, bridgeLink};
  }, [meta, tailParam]);

  const [landingUrl, setLandingUrl] = useState<string>();

  const generateLandingLink = useCallback(
    (item) => {
      let query = {};

      if (typeof meta.paramKey === 'string') {
        query = {[meta.paramKey]: item.value};
      } else if (Array.isArray(meta.paramKey)) {
        query = meta.paramKey.reduce((prev, cur) => {
          return {...prev, [cur]: item[cur]};
        }, {});
      }

      return isBridge
        ? getBridgeLink(meta.host, {...query, tailParam})
        : getDeepLink(meta.host, {...query, tailParam});
    },
    [isBridge, meta, tailParam]
  );

  useEffect(() => {
    return () => {
      setLandingUrl('');
    };
  }, [isBridge]);

  return (
    <div className={styles.link_map}>
      <h3>{meta.header}</h3>
      <p className={styles.preview}>
        {decodeURIComponent(isBridge ? preview.bridgeLink : preview.deepLink)}
      </p>
      {landingUrl && (
        <p className={styles.preview}>
          {landingUrl}
          <button
            className={styles.copy_button}
            onClick={(e) => {
              e.preventDefault();
              copyText(landingUrl);
            }}
          >
            복사
          </button>
        </p>
      )}

      <div className={styles.link_wrap}>
        {data.map(({title, ...n}, idx) => {
          const url = generateLandingLink(n);
          return (
            <button
              key={`${meta.header}-${idx}`}
              className={classNames({
                [styles.none]: !!n.broken,
                [styles.is_selected]: url === landingUrl,
              })}
              onClick={() => setLandingUrl(url)}
            >
              <em>{n.value || Object.values(n).join(',')}</em>
              {title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const LinkList = ({isBridge}) => {
  const [tailParam, setTailParam] = useState({});
  const [keyValue, setKeyValue] = useState(['', '']);

  return (
    <>
      <h2>tailParam 생성</h2>
      <div>
        <p>{JSON.stringify(tailParam)}</p>
        <p>
          key :{' '}
          <input
            value={keyValue[0]}
            onChange={(e) => {
              setKeyValue((kv) => [e.target.value, kv[1]]);
            }}
          />
        </p>
        <p>
          value :{' '}
          <input
            value={keyValue[1]}
            onChange={(e) => {
              setKeyValue((kv) => [kv[0], e.target.value]);
            }}
          />
        </p>
        <button
          onClick={() => {
            keyValue[0] &&
              keyValue[1] &&
              setTailParam((prev) => ({...prev, [keyValue[0]]: keyValue[1]}));
          }}
        >
          추가
        </button>
        <button
          onClick={() => {
            setTailParam({});
            setKeyValue(['', '']);
          }}
        >
          초기화
        </button>
      </div>

      <LinkMap
        meta={{header: '티지금 메인 > 상단 카테고리', host: 'nearby', paramKey: 'reqKey'}}
        data={DEFAULT_VALID_CATEGORY_LINK.map((d) => ({
          title: d.label,
          value: d.type,
        }))}
        isBridge={isBridge}
        tailParam={tailParam}
      />

      <LinkMap
        meta={{header: '상세 - poiid', host: 'poi-detail', paramKey: 'poiid'}}
        data={POI_ID_MAP}
        isBridge={isBridge}
        tailParam={tailParam}
      />

      <LinkMap
        meta={{header: '상세 = pkey', host: 'poi-detail', paramKey: 'pkey'}}
        data={POI_KEY_MAP}
        isBridge={isBridge}
        tailParam={tailParam}
      />

      <LinkMap
        meta={{header: '검색진입', host: 'search', paramKey: 'name'}}
        data={SEARCH_QUERIES.map((q) => ({title: q, value: q}))}
        isBridge={isBridge}
        tailParam={tailParam}
      />
    </>
  );
};

const HOST_LIST = [ESearchWebHost.PROD_DOMAIN, ESearchWebHost.STAGE_DOMAIN];
const GUIDE_LIST = [
  'search_home',
  'navigation',
  'public_transportation',
  'place_tab',
  'ev_panel',
  'instagram_inflow',
];

const KEY_GUIDE_LIST = ['SS_WATER_PLAY', 'SS_CHERRY_BLOSSOM', 'AD_MCDONALD', 'SS_HOLIDAY_PARK'];

const QuickSearch = () => {
  const [referrer, setReferrer] = useState('');
  const [reqKey, setReqKey] = useState('');
  const [result, setResult] = useState<string[]>([]);

  return (
    <div className={styles.quick_search_wrap}>
      <div>
        log_all_referrer :
        <input placeholder="navigation" onChange={(e) => setReferrer(e.target.value)} />
        <div className={styles.desc}>
          {GUIDE_LIST.map((g) => (
            <span key={g}>{g}</span>
          ))}
        </div>
      </div>
      <div>
        reqKey :
        <input placeholder="SS_WATER_PLAY" onChange={(e) => setReqKey(e.target.value)} />
        <div className={styles.desc}>
          {KEY_GUIDE_LIST.map((g) => (
            <span key={g}>{g}</span>
          ))}
        </div>
      </div>
      <button
        onClick={() => {
          const params = referrer
            ? {
                log_all_referrer: referrer,
              }
            : {};

          const g = (domain, reqMode, p?) =>
            generateUrl(`${domain}${Paths.PlaceCategory}`, {
              type: reqKey,
              reqMode,
              ...(p ? {tailParam: JSON.stringify(p)} : {}),
            });

          const deeplink = getDeepLink('nearby', {
            reqKey,
            tailParam: params,
          });

          const bridgeLink = getBridgeLink('nearby', {
            reqKey,
            tailParam: params,
          });

          const fromNaviLink = HOST_LIST.reduce((arr, domain) => {
            return [
              ...arr,
              g(domain, EAppRequestMode.MAIN),
              g(domain, EAppRequestMode.MAIN, {
                log_all_referrer: 'navigation',
              }),
            ] as any;
          }, []);

          const fromPublicTrans = HOST_LIST.reduce((arr, domain) => {
            return [
              ...arr,
              g(domain, EAppRequestMode.PUBLIC_TRANSPORT),
              g(domain, EAppRequestMode.PUBLIC_TRANSPORT, {
                log_all_referrer: 'public_transportation',
              }),
            ] as any;
          }, []);

          setResult([
            'deeplink',
            deeplink,
            '브릿지 링크',
            bridgeLink,
            '내비 퀵서치 등록',
            ...fromNaviLink,
            '대중교통 퀵서치 등록',
            ...fromPublicTrans,
          ]);
        }}
      >
        생성
      </button>

      <div className={styles.result_wrap}>
        {result.map((r) =>
          r.includes('://') ? (
            <span key={r} className={styles.link} onClick={() => copyText(r, false)}>
              {r}
            </span>
          ) : (
            <>
              <br />
              <span>{r}</span>
            </>
          )
        )}
      </div>
    </div>
  );
};

enum ETab {
  BRIDGE = 'bridge',
  DEEP_LINK = 'deep_link',
  QUICK_SEARCH = 'quick_search',
}

const TAB_LIST = [
  {type: ETab.BRIDGE, title: '브릿지'},
  {type: ETab.DEEP_LINK, title: '딥링크'},
  {type: ETab.QUICK_SEARCH, title: '퀵서치'},
];

const DevDeepLinkPage = () => {
  const [tab, setTab] = useState<ETab>(ETab.BRIDGE);
  const isBridge = tab === ETab.BRIDGE;

  return (
    <div className={styles.wrap}>
      <h1>DeepLink</h1>
      <div>
        {TAB_LIST.map((t) => (
          <button
            key={t.type}
            className={classNames({
              [styles.is_selected]: t.type === tab,
            })}
            onClick={() => setTab(t.type)}
          >
            {t.title} 링크 생성
          </button>
        ))}
      </div>

      {tab === ETab.QUICK_SEARCH ? <QuickSearch /> : <LinkList isBridge={isBridge} />}
    </div>
  );
};

export default DevDeepLinkPage;
