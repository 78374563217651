import {NARROW_WINDOW_SIZE, WIDE_WINDOW_SIZE} from 'constant/Size';
import {useAppSelector} from 'ducks/hooks';
import {useCallback} from 'react';
import {TPoiImageInfo} from 'types/App';

const MIN_VIEW_IMAGE_COUNT = 3;
const DEFAULT_VIEW_IMAGE_COUNT = 3;
const MAX_VIEW_IMAGE_COUNT = 5;
const TNOW_DEFAULT_COUNT = 3;
const TNOW_MAX_COUNT = 5;

export const useSearchImageList = () => {
  const {isLandscape, windowWidth, windowHeight} = useAppSelector((state) => ({
    windowWidth: state.layout.windowSize.width,
    windowHeight: state.layout.windowSize.height,
    isLandscape: state.layout.appSize.isLandscape,
  }));

  const getListByViewport = useCallback(
    (imageInfo: TPoiImageInfo[] = []): TPoiImageInfo[] => {
      const isNarrowView = windowWidth <= NARROW_WINDOW_SIZE;
      const isWideView = windowWidth >= WIDE_WINDOW_SIZE;
      const checkImage = (count) => (imageInfo.length >= count ? imageInfo.slice(0, count) : []);

      if (isLandscape) {
        const isNarrowPortrait = windowHeight <= NARROW_WINDOW_SIZE;
        return checkImage(isNarrowPortrait ? MIN_VIEW_IMAGE_COUNT : DEFAULT_VIEW_IMAGE_COUNT);
      }

      if (isNarrowView) {
        return checkImage(MIN_VIEW_IMAGE_COUNT);
      }

      if (isWideView) {
        const result = checkImage(MAX_VIEW_IMAGE_COUNT);

        if (result.length > 0) {
          return result;
        }
      }

      return checkImage(DEFAULT_VIEW_IMAGE_COUNT);
    },
    [isLandscape, windowHeight, windowWidth]
  );

  const getTNowListByViewPort = useCallback(
    (imageInfo: TPoiImageInfo[] = []): TPoiImageInfo[] => {
      const isWideView = windowWidth >= WIDE_WINDOW_SIZE;
      const checkImage = (count) => imageInfo.slice(0, count);

      if (isWideView && !isLandscape) {
        return checkImage(TNOW_MAX_COUNT);
      }

      return checkImage(TNOW_DEFAULT_COUNT);
    },
    [isLandscape, windowWidth]
  );

  return {getListByViewport, getTNowListByViewPort};
};
