import {TPoiDataJson} from '@lcc/tmap-inapp';
import {TPersonalPlaceItem} from 'ducks/userInfo/types';
import {EListMode} from 'types/ListDrawer';
import {EMarkerType, TLonLat} from 'types/Map';

export type TVsmPublicTransportType = 'busstop' | 'subway';
export type TCalloutInfo = TLonLat & {
  // https://tmobi.atlassian.net/browse/TMAPONE-421
  stationSktId: string; // stationSktId
  publicTransportType?: TVsmPublicTransportType;
  publicTransportName?: string;
} & Partial<
    {
      markerType: EMarkerType;
    } & TPersonalPlaceItem
  >;

export enum EHistoryStack {
  CALLOUT = 'callout',
  EDIT_RECENT_TAB = 'editRecentTab',
  BOTTOM_POPUP_MODAL = 'bottomPopupModal',
  CUSTOM_SELECT_LAYER = 'customSelectLayer',
  ROUTE_ADD_POPUP = 'routeAddPopup',
}

export type TUserInteraction = {
  activePoi?: string;
  activeTargetPoi?: string;
  activeTargetSetTime?: number;
  trigger?: 'scroll' | 'marker' | 'rotate' | 'none';
  drawerMode?: EListMode;
  calloutInfo?: TCalloutInfo;
  pickOnMapCalloutInfo?: TCalloutInfo;
  dragMap?: boolean;
  resumeKey?: number;
  refreshKey?: number;
  refreshStart?: boolean;
  drivingPoiDataJson?: TPoiDataJson;
  historyStack?: EHistoryStack[];
};
