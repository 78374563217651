import custom_base_icon from 'resource/pubImages/markers/custom_base_icon@3x.png';
import custom_base_marker from 'resource/pubImages/markers/custom_base_marker@3x.png';
import mapmarker_blossom_normal_day from 'resource/pubImages/markers/mapmarker_blossom_normal_day@3x.png';
import mapmarker_blossom_select_day from 'resource/pubImages/markers/mapmarker_blossom_select_day@3x.png';
import mapmarker_busstop_select from 'resource/pubImages/markers/mapmarker_busstop_select@3x.png';
import mapmarker_ev_icon_HUMAX from 'resource/pubImages/markers/mapmarker_ev_icon_HUMAX@3x.png';
import mapmarker_ev_icon_LG from 'resource/pubImages/markers/mapmarker_ev_icon_LG@3x.png';
import mapmarker_ev_icon_POWERCUBE from 'resource/pubImages/markers/mapmarker_ev_icon_POWERCUBE@3x.png';
import mapmarker_ev_icon_SG from 'resource/pubImages/markers/mapmarker_ev_icon_SG@3x.png';
import mapmarker_ev_icon_SK from 'resource/pubImages/markers/mapmarker_ev_icon_SK@3x.png';
import mapmarker_ev_icon_SPHAROSEV from 'resource/pubImages/markers/mapmarker_ev_icon_SPHAROSEV@3x.png';
import mapmarker_ev_icon_brandx from 'resource/pubImages/markers/mapmarker_ev_icon_brandx@3x.png';
import mapmarker_ev_icon_chaevi from 'resource/pubImages/markers/mapmarker_ev_icon_chaevi@3x.png';
import mapmarker_ev_icon_chargev from 'resource/pubImages/markers/mapmarker_ev_icon_chargev@3x.png';
import mapmarker_ev_icon_ecar from 'resource/pubImages/markers/mapmarker_ev_icon_ecar@3x.png';
import mapmarker_ev_icon_everon from 'resource/pubImages/markers/mapmarker_ev_icon_everon@3x.png';
import mapmarker_ev_icon_evz from 'resource/pubImages/markers/mapmarker_ev_icon_evz@3x.png';
import mapmarker_ev_icon_gntel from 'resource/pubImages/markers/mapmarker_ev_icon_gntel@3x.png';
import mapmarker_ev_icon_jeju from 'resource/pubImages/markers/mapmarker_ev_icon_jeju@3x.png';
import mapmarker_ev_icon_kepco from 'resource/pubImages/markers/mapmarker_ev_icon_kepco@3x.png';
import mapmarker_ev_icon_kevcs from 'resource/pubImages/markers/mapmarker_ev_icon_kevcs@3x.png';
import mapmarker_ev_icon_kevit from 'resource/pubImages/markers/mapmarker_ev_icon_kevit@3x.png';
import mapmarker_ev_icon_klin from 'resource/pubImages/markers/mapmarker_ev_icon_klin@3x.png';
import mapmarker_ev_icon_me from 'resource/pubImages/markers/mapmarker_ev_icon_me@3x.png';
import mapmarker_ev_icon_speed_fast_off from 'resource/pubImages/markers/mapmarker_ev_icon_speed_fast_off@3x.png';
import mapmarker_ev_icon_speed_fast_on from 'resource/pubImages/markers/mapmarker_ev_icon_speed_fast_on@3x.png';
import mapmarker_ev_icon_speed_super_fast_off from 'resource/pubImages/markers/mapmarker_ev_icon_speed_super_fast_off@3x.png';
import mapmarker_ev_icon_speed_super_fast_on from 'resource/pubImages/markers/mapmarker_ev_icon_speed_super_fast_on@3x.png';
import mapmarker_ev_icon_ss from 'resource/pubImages/markers/mapmarker_ev_icon_ss@3x.png';
import mapmarker_ev_icon_starkoff from 'resource/pubImages/markers/mapmarker_ev_icon_starkoff@3x.png';
import mapmarker_ev_icon_tesla from 'resource/pubImages/markers/mapmarker_ev_icon_tesla@3x.png';
import mapmarker_ev_icon_volvo from 'resource/pubImages/markers/mapmarker_ev_icon_volvo@3x.png';
import mapmarker_ev_marker_HUMAX from 'resource/pubImages/markers/mapmarker_ev_marker_HUMAX@3x.png';
import mapmarker_ev_marker_LG from 'resource/pubImages/markers/mapmarker_ev_marker_LG@3x.png';
import mapmarker_ev_marker_POWERCUBE from 'resource/pubImages/markers/mapmarker_ev_marker_POWERCUBE@3x.png';
import mapmarker_ev_marker_SG from 'resource/pubImages/markers/mapmarker_ev_marker_SG@3x.png';
import mapmarker_ev_marker_SK from 'resource/pubImages/markers/mapmarker_ev_marker_SK@3x.png';
import mapmarker_ev_marker_SPHAROSEV from 'resource/pubImages/markers/mapmarker_ev_marker_SPHAROSEV@3x.png';
import mapmarker_ev_marker_brandx from 'resource/pubImages/markers/mapmarker_ev_marker_brandx@3x.png';
import mapmarker_ev_marker_chaevi from 'resource/pubImages/markers/mapmarker_ev_marker_chaevi@3x.png';
import mapmarker_ev_marker_chargev from 'resource/pubImages/markers/mapmarker_ev_marker_chargev@3x.png';
import mapmarker_ev_marker_ecar from 'resource/pubImages/markers/mapmarker_ev_marker_ecar@3x.png';
import mapmarker_ev_marker_everon from 'resource/pubImages/markers/mapmarker_ev_marker_everon@3x.png';
import mapmarker_ev_marker_evz from 'resource/pubImages/markers/mapmarker_ev_marker_evz@3x.png';
import mapmarker_ev_marker_gntel from 'resource/pubImages/markers/mapmarker_ev_marker_gntel@3x.png';
import mapmarker_ev_marker_jeju from 'resource/pubImages/markers/mapmarker_ev_marker_jeju@3x.png';
import mapmarker_ev_marker_kepco from 'resource/pubImages/markers/mapmarker_ev_marker_kepco@3x.png';
import mapmarker_ev_marker_kevcs from 'resource/pubImages/markers/mapmarker_ev_marker_kevcs@3x.png';
import mapmarker_ev_marker_kevit from 'resource/pubImages/markers/mapmarker_ev_marker_kevit@3x.png';
import mapmarker_ev_marker_klin from 'resource/pubImages/markers/mapmarker_ev_marker_klin@3x.png';
import mapmarker_ev_marker_me from 'resource/pubImages/markers/mapmarker_ev_marker_me@3x.png';
import mapmarker_ev_marker_speed_fast_off from 'resource/pubImages/markers/mapmarker_ev_marker_speed_fast_off@3x.png';
import mapmarker_ev_marker_speed_fast_on from 'resource/pubImages/markers/mapmarker_ev_marker_speed_fast_on@3x.png';
import mapmarker_ev_marker_speed_super_fast_off from 'resource/pubImages/markers/mapmarker_ev_marker_speed_super_fast_off@3x.png';
import mapmarker_ev_marker_speed_super_fast_on from 'resource/pubImages/markers/mapmarker_ev_marker_speed_super_fast_on@3x.png';
import mapmarker_ev_marker_ss from 'resource/pubImages/markers/mapmarker_ev_marker_ss@3x.png';
import mapmarker_ev_marker_starkoff from 'resource/pubImages/markers/mapmarker_ev_marker_starkoff@3x.png';
import mapmarker_ev_marker_tesla from 'resource/pubImages/markers/mapmarker_ev_marker_tesla@3x.png';
import mapmarker_ev_marker_volvo from 'resource/pubImages/markers/mapmarker_ev_marker_volvo@3x.png';
import mapmarker_fall_marker_day from 'resource/pubImages/markers/mapmarker_fall_marker_day@3x.png';
import mapmarker_fall_marker_night from 'resource/pubImages/markers/mapmarker_fall_marker_night@3x.png';
import mapmarker_fall_normal_day from 'resource/pubImages/markers/mapmarker_fall_normal_day@3x.png';
import mapmarker_fall_normal_night from 'resource/pubImages/markers/mapmarker_fall_normal_night@3x.png';
import mapmarker_favorite_home from 'resource/pubImages/markers/mapmarker_favorite_home@3x.png';
import mapmarker_favorite_office from 'resource/pubImages/markers/mapmarker_favorite_office@3x.png';
import mapmarker_favorite_poi from 'resource/pubImages/markers/mapmarker_favorite_poi@3x.png';
import mapmarker_favorite_public from 'resource/pubImages/markers/mapmarker_favorite_public@3x.png';
import mapmarker_holiday_parking_icon_day_general from 'resource/pubImages/markers/mapmarker_holiday_parking_icon_day_general@3x.png';
import mapmarker_holiday_parking_icon_night_general from 'resource/pubImages/markers/mapmarker_holiday_parking_icon_night_general@3x.png';
import mapmarker_holiday_parking_marker_day_general from 'resource/pubImages/markers/mapmarker_holiday_parking_marker_day_general@3x.png';
import mapmarker_holiday_parking_marker_night_general from 'resource/pubImages/markers/mapmarker_holiday_parking_marker_night_general@3x.png';
import mapmarker_jinhae_normal_day from 'resource/pubImages/markers/mapmarker_jinhae_normal_day@3x.png';
import mapmarker_jinhae_normal_night from 'resource/pubImages/markers/mapmarker_jinhae_normal_night@3x.png';
import mapmarker_jinhae_select_day from 'resource/pubImages/markers/mapmarker_jinhae_select_day@3x.png';
import mapmarker_jinhae_select_night from 'resource/pubImages/markers/mapmarker_jinhae_select_night@3x.png';
import mapmarker_jinhaeblossom_normal_day from 'resource/pubImages/markers/mapmarker_jinhaeblossom_normal_day@3x.png';
import mapmarker_jinhaeblossom_select_day from 'resource/pubImages/markers/mapmarker_jinhaeblossom_select_day@3x.png';
import mapmarker_mc_normal_day from 'resource/pubImages/markers/mapmarker_mc_normal_day@3x.png';
import mapmarker_mc_normal_night from 'resource/pubImages/markers/mapmarker_mc_normal_night@3x.png';
import mapmarker_mc_select_day from 'resource/pubImages/markers/mapmarker_mc_select_day@3x.png';
import mapmarker_mc_select_night from 'resource/pubImages/markers/mapmarker_mc_select_night@3x.png';
import mapmarker_oil_icon_e1 from 'resource/pubImages/markers/mapmarker_oil_icon_e1@3x.png';
import mapmarker_oil_icon_gs from 'resource/pubImages/markers/mapmarker_oil_icon_gs@3x.png';
import mapmarker_oil_icon_oil from 'resource/pubImages/markers/mapmarker_oil_icon_oil@3x.png';
import mapmarker_oil_icon_oilbank from 'resource/pubImages/markers/mapmarker_oil_icon_oilbank@3x.png';
import mapmarker_oil_icon_sk from 'resource/pubImages/markers/mapmarker_oil_icon_sk@3x.png';
import mapmarker_oil_icon_soil from 'resource/pubImages/markers/mapmarker_oil_icon_soil@3x.png';
import mapmarker_oil_marker_e1 from 'resource/pubImages/markers/mapmarker_oil_marker_e1@3x.png';
import mapmarker_oil_marker_gs from 'resource/pubImages/markers/mapmarker_oil_marker_gs@3x.png';
import mapmarker_oil_marker_oil from 'resource/pubImages/markers/mapmarker_oil_marker_oil@3x.png';
import mapmarker_oil_marker_oilbank from 'resource/pubImages/markers/mapmarker_oil_marker_oilbank@3x.png';
import mapmarker_oil_marker_sk from 'resource/pubImages/markers/mapmarker_oil_marker_sk@3x.png';
import mapmarker_oil_marker_soil from 'resource/pubImages/markers/mapmarker_oil_marker_soil@3x.png';
import mapmarker_parking_icon_day_general from 'resource/pubImages/markers/mapmarker_parking_icon_day_general@3x.png';
import mapmarker_parking_icon_day_partner from 'resource/pubImages/markers/mapmarker_parking_icon_day_partner@3x.png';
import mapmarker_parking_icon_night_general from 'resource/pubImages/markers/mapmarker_parking_icon_night_general@3x.png';
import mapmarker_parking_icon_night_partner from 'resource/pubImages/markers/mapmarker_parking_icon_night_partner@3x.png';
import mapmarker_parking_marker_day_general from 'resource/pubImages/markers/mapmarker_parking_marker_day_general@3x.png';
import mapmarker_parking_marker_day_partner from 'resource/pubImages/markers/mapmarker_parking_marker_day_partner@3x.png';
import mapmarker_parking_marker_night_general from 'resource/pubImages/markers/mapmarker_parking_marker_night_general@3x.png';
import mapmarker_parking_marker_night_partner from 'resource/pubImages/markers/mapmarker_parking_marker_night_partner@3x.png';
import mapmarker_pin_day from 'resource/pubImages/markers/mapmarker_pin_day@3x.png';
import mapmarker_pin_night from 'resource/pubImages/markers/mapmarker_pin_night@3x.png';
import mapmarker_place_normal_day from 'resource/pubImages/markers/mapmarker_place_normal_day@3x.png';
import mapmarker_place_normal_night from 'resource/pubImages/markers/mapmarker_place_normal_night@3x.png';
import mapmarker_place_select_day from 'resource/pubImages/markers/mapmarker_place_select_day@3x.png';
import mapmarker_place_select_night from 'resource/pubImages/markers/mapmarker_place_select_night@3x.png';
import mapmarker_recent from 'resource/pubImages/markers/mapmarker_recent@3x.png';
import mapmarker_store_icon_cafe_day from 'resource/pubImages/markers/mapmarker_store_icon_cafe_day@3x.png';
import mapmarker_store_icon_cafe_night from 'resource/pubImages/markers/mapmarker_store_icon_cafe_night@3x.png';
import mapmarker_store_icon_chicken_day from 'resource/pubImages/markers/mapmarker_store_icon_chicken_day@3x.png';
import mapmarker_store_icon_chicken_night from 'resource/pubImages/markers/mapmarker_store_icon_chicken_night@3x.png';
import mapmarker_store_icon_cu from 'resource/pubImages/markers/mapmarker_store_icon_cu@3x.png';
import mapmarker_store_icon_dessert_day from 'resource/pubImages/markers/mapmarker_store_icon_dessert_day@3x.png';
import mapmarker_store_icon_dessert_night from 'resource/pubImages/markers/mapmarker_store_icon_dessert_night@3x.png';
import mapmarker_store_icon_emart24 from 'resource/pubImages/markers/mapmarker_store_icon_emart24@3x.png';
import mapmarker_store_icon_gs25 from 'resource/pubImages/markers/mapmarker_store_icon_gs25@3x.png';
import mapmarker_store_icon_ministop from 'resource/pubImages/markers/mapmarker_store_icon_ministop@3x.png';
import mapmarker_store_icon_restaurant_day from 'resource/pubImages/markers/mapmarker_store_icon_restaurant_day@3x.png';
import mapmarker_store_icon_restaurant_night from 'resource/pubImages/markers/mapmarker_store_icon_restaurant_night@3x.png';
import mapmarker_store_icon_sevenEleven from 'resource/pubImages/markers/mapmarker_store_icon_sevenEleven@3x.png';
import mapmarker_store_icon_store from 'resource/pubImages/markers/mapmarker_store_icon_store@3x.png';
import mapmarker_store_marker_cafe_day from 'resource/pubImages/markers/mapmarker_store_marker_cafe_day@3x.png';
import mapmarker_store_marker_cafe_night from 'resource/pubImages/markers/mapmarker_store_marker_cafe_night@3x.png';
import mapmarker_store_marker_chicken_day from 'resource/pubImages/markers/mapmarker_store_marker_chicken_day@3x.png';
import mapmarker_store_marker_chicken_night from 'resource/pubImages/markers/mapmarker_store_marker_chicken_night@3x.png';
import mapmarker_store_marker_cu from 'resource/pubImages/markers/mapmarker_store_marker_cu@3x.png';
import mapmarker_store_marker_dessert_day from 'resource/pubImages/markers/mapmarker_store_marker_dessert_day@3x.png';
import mapmarker_store_marker_dessert_night from 'resource/pubImages/markers/mapmarker_store_marker_dessert_night@3x.png';
import mapmarker_store_marker_emart24 from 'resource/pubImages/markers/mapmarker_store_marker_emart24@3x.png';
import mapmarker_store_marker_gs25 from 'resource/pubImages/markers/mapmarker_store_marker_gs25@3x.png';
import mapmarker_store_marker_ministop from 'resource/pubImages/markers/mapmarker_store_marker_ministop@3x.png';
import mapmarker_store_marker_restaurant_day from 'resource/pubImages/markers/mapmarker_store_marker_restaurant_day@3x.png';
import mapmarker_store_marker_restaurant_night from 'resource/pubImages/markers/mapmarker_store_marker_restaurant_night@3x.png';
import mapmarker_store_marker_sevenEleven from 'resource/pubImages/markers/mapmarker_store_marker_sevenEleven@3x.png';
import mapmarker_store_marker_store from 'resource/pubImages/markers/mapmarker_store_marker_store@3x.png';
import mapmarker_subway_select from 'resource/pubImages/markers/mapmarker_subway_select@3x.png';
import marker_TRACK_POINT from 'resource/pubImages/markers/marker_TRACK_POINT@3x.png';
import waterpark_icon_day from 'resource/pubImages/markers/waterpark-icon-day@3x.png';
import waterpark_icon_night from 'resource/pubImages/markers/waterpark-icon-night@3x.png';
import waterpark_marker_day from 'resource/pubImages/markers/waterpark-marker-day@3x.png';
import waterpark_marker_night from 'resource/pubImages/markers/waterpark-marker-night@3x.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  custom: {base: {icon: custom_base_icon, marker: custom_base_marker}},
  mapmarker: {
    blossom: {
      normal: {day: mapmarker_blossom_normal_day},
      select: {day: mapmarker_blossom_select_day},
    },
    busstop: {select: mapmarker_busstop_select},
    ev: {
      icon: {
        HUMAX: mapmarker_ev_icon_HUMAX,
        LG: mapmarker_ev_icon_LG,
        POWERCUBE: mapmarker_ev_icon_POWERCUBE,
        SG: mapmarker_ev_icon_SG,
        SK: mapmarker_ev_icon_SK,
        SPHAROSEV: mapmarker_ev_icon_SPHAROSEV,
        brandx: mapmarker_ev_icon_brandx,
        chaevi: mapmarker_ev_icon_chaevi,
        chargev: mapmarker_ev_icon_chargev,
        ecar: mapmarker_ev_icon_ecar,
        everon: mapmarker_ev_icon_everon,
        evz: mapmarker_ev_icon_evz,
        gntel: mapmarker_ev_icon_gntel,
        jeju: mapmarker_ev_icon_jeju,
        kepco: mapmarker_ev_icon_kepco,
        kevcs: mapmarker_ev_icon_kevcs,
        kevit: mapmarker_ev_icon_kevit,
        klin: mapmarker_ev_icon_klin,
        me: mapmarker_ev_icon_me,
        speed: {
          fast: {off: mapmarker_ev_icon_speed_fast_off, on: mapmarker_ev_icon_speed_fast_on},
          super: {
            fast: {
              off: mapmarker_ev_icon_speed_super_fast_off,
              on: mapmarker_ev_icon_speed_super_fast_on,
            },
          },
        },
        ss: mapmarker_ev_icon_ss,
        starkoff: mapmarker_ev_icon_starkoff,
        tesla: mapmarker_ev_icon_tesla,
        volvo: mapmarker_ev_icon_volvo,
      },
      marker: {
        HUMAX: mapmarker_ev_marker_HUMAX,
        LG: mapmarker_ev_marker_LG,
        POWERCUBE: mapmarker_ev_marker_POWERCUBE,
        SG: mapmarker_ev_marker_SG,
        SK: mapmarker_ev_marker_SK,
        SPHAROSEV: mapmarker_ev_marker_SPHAROSEV,
        brandx: mapmarker_ev_marker_brandx,
        chaevi: mapmarker_ev_marker_chaevi,
        chargev: mapmarker_ev_marker_chargev,
        ecar: mapmarker_ev_marker_ecar,
        everon: mapmarker_ev_marker_everon,
        evz: mapmarker_ev_marker_evz,
        gntel: mapmarker_ev_marker_gntel,
        jeju: mapmarker_ev_marker_jeju,
        kepco: mapmarker_ev_marker_kepco,
        kevcs: mapmarker_ev_marker_kevcs,
        kevit: mapmarker_ev_marker_kevit,
        klin: mapmarker_ev_marker_klin,
        me: mapmarker_ev_marker_me,
        speed: {
          fast: {off: mapmarker_ev_marker_speed_fast_off, on: mapmarker_ev_marker_speed_fast_on},
          super: {
            fast: {
              off: mapmarker_ev_marker_speed_super_fast_off,
              on: mapmarker_ev_marker_speed_super_fast_on,
            },
          },
        },
        ss: mapmarker_ev_marker_ss,
        starkoff: mapmarker_ev_marker_starkoff,
        tesla: mapmarker_ev_marker_tesla,
        volvo: mapmarker_ev_marker_volvo,
      },
    },
    fall: {
      marker: {day: mapmarker_fall_marker_day, night: mapmarker_fall_marker_night},
      normal: {day: mapmarker_fall_normal_day, night: mapmarker_fall_normal_night},
    },
    favorite: {
      home: mapmarker_favorite_home,
      office: mapmarker_favorite_office,
      poi: mapmarker_favorite_poi,
      public: mapmarker_favorite_public,
    },
    holiday: {
      parking: {
        icon: {
          day: {general: mapmarker_holiday_parking_icon_day_general},
          night: {general: mapmarker_holiday_parking_icon_night_general},
        },
        marker: {
          day: {general: mapmarker_holiday_parking_marker_day_general},
          night: {general: mapmarker_holiday_parking_marker_night_general},
        },
      },
    },
    jinhae: {
      normal: {day: mapmarker_jinhae_normal_day, night: mapmarker_jinhae_normal_night},
      select: {day: mapmarker_jinhae_select_day, night: mapmarker_jinhae_select_night},
    },
    jinhaeblossom: {
      normal: {day: mapmarker_jinhaeblossom_normal_day},
      select: {day: mapmarker_jinhaeblossom_select_day},
    },
    mc: {
      normal: {day: mapmarker_mc_normal_day, night: mapmarker_mc_normal_night},
      select: {day: mapmarker_mc_select_day, night: mapmarker_mc_select_night},
    },
    oil: {
      icon: {
        e1: mapmarker_oil_icon_e1,
        gs: mapmarker_oil_icon_gs,
        oil: mapmarker_oil_icon_oil,
        oilbank: mapmarker_oil_icon_oilbank,
        sk: mapmarker_oil_icon_sk,
        soil: mapmarker_oil_icon_soil,
      },
      marker: {
        e1: mapmarker_oil_marker_e1,
        gs: mapmarker_oil_marker_gs,
        oil: mapmarker_oil_marker_oil,
        oilbank: mapmarker_oil_marker_oilbank,
        sk: mapmarker_oil_marker_sk,
        soil: mapmarker_oil_marker_soil,
      },
    },
    parking: {
      icon: {
        day: {
          general: mapmarker_parking_icon_day_general,
          partner: mapmarker_parking_icon_day_partner,
        },
        night: {
          general: mapmarker_parking_icon_night_general,
          partner: mapmarker_parking_icon_night_partner,
        },
      },
      marker: {
        day: {
          general: mapmarker_parking_marker_day_general,
          partner: mapmarker_parking_marker_day_partner,
        },
        night: {
          general: mapmarker_parking_marker_night_general,
          partner: mapmarker_parking_marker_night_partner,
        },
      },
    },
    pin: {day: mapmarker_pin_day, night: mapmarker_pin_night},
    place: {
      normal: {day: mapmarker_place_normal_day, night: mapmarker_place_normal_night},
      select: {day: mapmarker_place_select_day, night: mapmarker_place_select_night},
    },
    recent: mapmarker_recent,
    store: {
      icon: {
        cafe: {day: mapmarker_store_icon_cafe_day, night: mapmarker_store_icon_cafe_night},
        chicken: {day: mapmarker_store_icon_chicken_day, night: mapmarker_store_icon_chicken_night},
        cu: mapmarker_store_icon_cu,
        dessert: {day: mapmarker_store_icon_dessert_day, night: mapmarker_store_icon_dessert_night},
        emart24: mapmarker_store_icon_emart24,
        gs25: mapmarker_store_icon_gs25,
        ministop: mapmarker_store_icon_ministop,
        restaurant: {
          day: mapmarker_store_icon_restaurant_day,
          night: mapmarker_store_icon_restaurant_night,
        },
        sevenEleven: mapmarker_store_icon_sevenEleven,
        store: mapmarker_store_icon_store,
      },
      marker: {
        cafe: {day: mapmarker_store_marker_cafe_day, night: mapmarker_store_marker_cafe_night},
        chicken: {
          day: mapmarker_store_marker_chicken_day,
          night: mapmarker_store_marker_chicken_night,
        },
        cu: mapmarker_store_marker_cu,
        dessert: {
          day: mapmarker_store_marker_dessert_day,
          night: mapmarker_store_marker_dessert_night,
        },
        emart24: mapmarker_store_marker_emart24,
        gs25: mapmarker_store_marker_gs25,
        ministop: mapmarker_store_marker_ministop,
        restaurant: {
          day: mapmarker_store_marker_restaurant_day,
          night: mapmarker_store_marker_restaurant_night,
        },
        sevenEleven: mapmarker_store_marker_sevenEleven,
        store: mapmarker_store_marker_store,
      },
    },
    subway: {select: mapmarker_subway_select},
  },
  marker: {TRACK: {POINT: marker_TRACK_POINT}},
  waterpark: {
    icon: {day: waterpark_icon_day, night: waterpark_icon_night},
    marker: {day: waterpark_marker_day, night: waterpark_marker_night},
  },
};
