import {useCallback, useMemo} from 'react';

import s from 'styles/components/search/SearchRecommendationItem.module.scss';

import {ReactComponent as IconRight} from 'resource/images/@tmds_element/ico_arrow_right.svg';

import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import {TSearchRecommend} from 'ducks/search/types';
import {NOW_SCORE_MIN_COUNT, WEEK_SCORE_MIN_COUNT} from 'constant/Search';
import {setComma} from 'utils/formatter';
import {sendSearchClickLog} from 'utils/logManager';
import {useAppSelector} from 'ducks/hooks';

type TProps = {
  data: TSearchRecommend;
  order: number;
  isExposeTime: boolean;
};

const SearchRecommendationItem = ({data, order, isExposeTime}: TProps) => {
  const {searchSessionKey} = useAppSelector((state) => ({
    searchSessionKey: state.userInfo.searchSessionKey,
  }));

  const {moveToDetail} = useMoveToTarget({from: EFromType.SEARCH_MAIN});

  const information = useMemo(
    () =>
      isExposeTime
        ? data.headingForScore > NOW_SCORE_MIN_COUNT
          ? `${setComma(data.headingForScore)}대 가는중`
          : ''
        : data.headingForWeekScore >= WEEK_SCORE_MIN_COUNT
        ? `길안내 ${setComma(data.headingForWeekScore)}번`
        : '',
    [isExposeTime, data.headingForScore, data.headingForWeekScore]
  );

  const handleClickList = useCallback(() => {
    moveToDetail(data);
    sendSearchClickLog('list_tap.near_poi_recommendation', {
      search_session_id: searchSessionKey,
      pkey: data.pkey,
      list_seq: order - 1,
    });
  }, []);

  return (
    <li className={s.wrap} onClick={handleClickList}>
      <div className={s.detail}>
        <div className={s.order}>{order}</div>
        <div className={s.location}>{data.poiName}</div>
        <div className={s.icon}>
          <IconRight />
        </div>
      </div>
      <div className={s.info}>{information}</div>
    </li>
  );
};

export default SearchRecommendationItem;
