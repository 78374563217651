import {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {EBusinessHourStatus, ECategoryGroup, TPoiImageInfo, TSpecialData} from 'types/App';
import {TPoiDataJson} from '@lcc/tmap-inapp';

import ImageLoader from 'components/ImageLoader';
import {ItemTag} from 'components/ItemTag';
import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';
import {IcoStarFilled} from 'components/@tmds/icons/IcoStarFilled';
import {IcoStar} from 'components/@tmds/icons/IcoStar';
import {TSpecialColor} from 'components/PoiListItemSpecialContent';

import {useParsePoiData} from 'hooks/useParsePoiData';
import useAppScheme from 'hooks/useAppScheme';
import useFavorite from 'hooks/useFavorite';
import useAddress from 'hooks/useAddress';
import useThrottle from 'hooks/useThrottle';
import {fetchUserData} from 'ducks/userInfo/slice';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {parsePoiInfoToNavInfo} from 'utils/search';
import useMoveToTarget from 'hooks/useMoveToTarget';

import s from 'styles/components/search/SearchRecommendPoiItem.module.scss';

const MAX_CATEGORY_NAME = 10;

export type TPoiData = {
  addressNameDepth2?: string;
  listName?: string;
  categoryName?: string;
  categoryGroup?: ECategoryGroup;
  special?: TSpecialData;
  imageInfo?: TPoiImageInfo[];
  pkey: string;
} & TPoiDataJson;

type TProps = {
  poiData: TPoiData;
  onClickItem?: (e) => void;
  onClickFavorite?: (isOn) => void;
};

const BUSINESS_HOUR_LABEL: Record<EBusinessHourStatus, [string, TSpecialColor] | null> = {
  [EBusinessHourStatus.TODAY_OFF]: ['오늘 휴무', 'red500'],
  [EBusinessHourStatus.BEFORE]: ['영업 전', 'red700'],
  [EBusinessHourStatus.OPEN]: ['영업 중', 'gray900'],
  [EBusinessHourStatus.CLOSED]: ['영업 종료', 'red700'],
};

const SearchRecommendPoiItem = ({poiData, onClickItem, onClickFavorite}: TProps) => {
  const personalPlace = useAppSelector((state) => state.userInfo.personalPlace);
  const dispatch = useAppDispatch();
  const inApp = useAppScheme();
  const ableToRun = useThrottle();
  const {checkIsFavorite} = useFavorite();
  const {fullAddress} = useAddress(poiData);
  const {distance, imageInfo: images, headingScore, tags} = useParsePoiData(poiData);
  const {moveToDetail} = useMoveToTarget();
  const [isFavorite, setIsFavorite] = useState(checkIsFavorite(poiData));
  const refPreviousPoiData = useRef<TPoiData | null>(null);
  const [loadedImages, setLoadedImages] = useState(new Set());

  const handleImageLoad = useCallback((index) => {
    setLoadedImages((prev) => new Set(prev).add(index));
  }, []);

  const businessHoursInfo =
    poiData.special?.businessHourStatus && BUSINESS_HOUR_LABEL[poiData.special.businessHourStatus];
  const [label, textColor] = businessHoursInfo || [];

  const handleClickFavorite = useCallback(
    (e) => {
      if (!poiData || !ableToRun()) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      inApp
        .toggleFavorite({
          ...parsePoiInfoToNavInfo({
            ...poiData,
            address: fullAddress,
          }),
        })
        .then((isOn) => {
          dispatch(fetchUserData());
          setIsFavorite(isOn ? true : false);
          onClickFavorite?.(isOn);
        });
    },
    [fullAddress, poiData, inApp, dispatch, ableToRun, onClickFavorite]
  );

  const handleClickItem = useCallback(
    (e) => {
      if (!ableToRun()) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      onClickItem?.(e);
      moveToDetail(parsePoiInfoToNavInfo(poiData));
    },
    [ableToRun, moveToDetail, poiData, onClickItem]
  );

  useEffect(() => {
    if (!poiData || !personalPlace.loaded) {
      return;
    }
    const isFavoriteCheck = checkIsFavorite(poiData);

    if (refPreviousPoiData.current !== poiData || isFavorite !== isFavoriteCheck) {
      setIsFavorite(isFavoriteCheck);
      refPreviousPoiData.current = poiData;
    }
  }, [poiData, checkIsFavorite, isFavorite, personalPlace]);

  return (
    <div className={s.wrap} onClick={handleClickItem}>
      <div className={s.title_wrap}>
        <div className={s.text_wrap}>
          <span className={s.title} data-style-node="title">
            {poiData.listName}
          </span>
          <span className={s.category} data-style-node="category">
            {poiData.categoryName?.slice(0, MAX_CATEGORY_NAME)}
          </span>
        </div>
        <div className={s.favorite} onClick={handleClickFavorite}>
          {isFavorite ? (
            <IcoStarFilled width={24} height={24} color="yellow500" />
          ) : (
            <IcoStar width={24} height={24} color="gray300" />
          )}
        </div>
      </div>

      <div className={s.info_wrap}>
        {!!businessHoursInfo && (
          <>
            <p className={s.operation} color={textColor}>
              {label}
            </p>
            <IcoLineBetween color="gray200" width={16} height={16} />
          </>
        )}
        <p className={s.distance}>{distance}</p>
        <IcoLineBetween color="gray200" width={16} height={16} />
        <p className={s.address}>{poiData?.addressNameDepth2}</p>
        {!!headingScore && <IcoLineBetween color="gray200" width={16} height={16} />}
        {!!headingScore && <span className={s.count}>{headingScore}대 가는중</span>}
      </div>

      <div
        className={classNames(s.special_action, {
          [s.is_hide]: tags.length < 1,
        })}
      >
        {tags.length > 0 && (
          <p className={s.tag_list}>
            {tags.map((t, tIdx) => (
              <span className={s.tag} key={tIdx}>
                <ItemTag {...t} />
              </span>
            ))}
          </p>
        )}
        <div className={classNames(s.image_wrap, {[s.hide]: images.length < 3})}>
          <div className={s.image_list}>
            {images.map((image, imgIdx) => {
              return (
                <div className={s.image_container} key={imgIdx}>
                  <div className={s.image} key={imgIdx}>
                    <ImageLoader
                      toggleClass={s.fade_in}
                      src={image.url || image.url2}
                      alt={`${poiData.listName} 이미지`}
                      onLoad={() => handleImageLoad(imgIdx)}
                      className={classNames({[s.loading]: !loadedImages.has(imgIdx)})}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchRecommendPoiItem;
