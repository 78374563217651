import {useCallback, useState} from 'react';
import {ReactComponent as IconExit} from 'resource/images/search_recent_ico_exit.svg';
import {ReactComponent as IconAI} from 'resource/images/AI.svg';
import {StorageKey} from 'constant/Storage';
import s from 'styles/components/search/SearchRecommendPopup.module.scss';

type TProps = {
  onClick: () => void;
};

const SearchRecommendPopup = ({onClick}: TProps) => {
  const [isVisible, setIsVisible] = useState(
    localStorage.getItem(StorageKey.SEARCH_RECOMMEND_POPUP_CLICKED) !== 'true'
  );

  const handleClickExit = useCallback(() => {
    localStorage.setItem(StorageKey.SEARCH_RECOMMEND_POPUP_CLICKED, 'true');
    onClick();
    setIsVisible(false);
  }, [onClick]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={s.wrap}>
      <div className={s.popup}>
        <div className={s.content}>
          <IconAI />
          <div className={s.text}>
            최근 주행 내역을 분석한 <span className={s.colored}>티맵 추천순</span>입니다.
          </div>
        </div>
        <div className={s.exit} onClick={handleClickExit}>
          <IconExit />
        </div>
      </div>
    </div>
  );
};

export default SearchRecommendPopup;
