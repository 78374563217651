import {TPoiDataJson} from '@lcc/tmap-inapp';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {EListMode} from 'types/ListDrawer';
import {EHistoryStack, TCalloutInfo, TUserInteraction} from './types';

import {devLog} from 'utils/dev';

const ACTION_PREFIX = 'user_interaction';

const initialState: TUserInteraction = {
  activePoi: '',
  activeTargetPoi: '',
  activeTargetSetTime: 0,
  trigger: 'none',
  drawerMode: EListMode.CENTER,
  calloutInfo: undefined,
  pickOnMapCalloutInfo: undefined,
  dragMap: false,
  resumeKey: 0,
  refreshKey: 0,
  refreshStart: false,
  drivingPoiDataJson: undefined,
  historyStack: [],
};

const userInteractionSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    setInteraction: (state, action: PayloadAction<Omit<TUserInteraction, 'calloutInfo'>>) => {
      if (action.payload.trigger === 'marker' && action.payload.activePoi) {
        state.activeTargetPoi = action.payload.activePoi;
        state.activeTargetSetTime = Date.now();
      }

      if (action.payload.trigger === 'scroll' && state.activeTargetPoi) {
        const timeDiff = Date.now() - (state.activeTargetSetTime || 0);
        if (timeDiff >= 500 || action.payload.activePoi === state.activeTargetPoi) {
          state.activeTargetPoi = '';
        } else {
          devLog('마커 클릭 후 잘못 발생하는 스크롤 이벤트 삭제');

          return;
        }
      }

      state.activePoi = action.payload.activePoi || state.activePoi;
      state.trigger = action.payload.trigger || state.trigger;
      state.drawerMode = action.payload.drawerMode || state.drawerMode;
      state.dragMap = action.payload.dragMap || state.dragMap;
    },
    setCalloutInfo: (state, action: PayloadAction<TCalloutInfo>) => {
      state.calloutInfo = {...action.payload};
      state.historyStack = [...(state.historyStack || []), EHistoryStack.CALLOUT];
    },
    setPickOnMapCalloutInfo: (state, action: PayloadAction<TCalloutInfo>) => {
      state.pickOnMapCalloutInfo = {...action.payload};
    },
    setDragMap: (state, action: PayloadAction<boolean>) => {
      state.dragMap = action.payload;
    },
    setResumeKey: (state, action: PayloadAction<number>) => {
      state.resumeKey = action.payload;
    },
    setRefreshKey: (state, action: PayloadAction<number>) => {
      state.refreshKey = action.payload;
    },
    clearCalloutInfo: (state) => {
      const nowHistoryStack = state.historyStack || [];
      const calloutHistoryIndex = nowHistoryStack.indexOf(EHistoryStack.CALLOUT);

      if (calloutHistoryIndex >= 0) {
        state.historyStack = nowHistoryStack.slice(0, calloutHistoryIndex);
      }

      state.calloutInfo = undefined;
    },
    clearPickOnMapCalloutInfo: (state) => {
      state.pickOnMapCalloutInfo = undefined;
    },
    pushHistoryStack: (state, action: PayloadAction<EHistoryStack>) => {
      state.historyStack = [...(state.historyStack || []), action.payload];
    },
    popHistoryStack: (state) => {
      state.historyStack = [...(state.historyStack?.slice(0, -1) || [])];
    },
    setRefreshStart: (state, action: PayloadAction<boolean>) => {
      state.refreshStart = action.payload;
    },
    resetListInteraction: (state) => {
      state.activePoi = undefined;
      state.drawerMode = EListMode.CENTER;
    },
    setDrivingPoiDataJson: (state, action: PayloadAction<TPoiDataJson | undefined>) => {
      state.drivingPoiDataJson = action.payload;
    },
  },
});

export default userInteractionSlice;
