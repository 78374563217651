import {EPlaceCategoryType} from 'types/App';
import {ESortOption} from 'types/Search';
import {isInHourRange} from 'utils/date';
import {TCategoryLinkProperty, TCategoryPageProperty} from 'ducks/category/types';
import {IS_WEEKEND} from 'constant/Date';

import IconPopular from 'resource/pubImages/icon/ico_place_popular@4x.png';
import IconOilStation from 'resource/pubImages/icon/ico_place_oil_station@4x.png';
import IconRestaurant from 'resource/pubImages/icon/ico_place_restaurant@4x.png';
import IconParking from 'resource/pubImages/icon/ico_place_parking@4x.png';
import IconEV from 'resource/pubImages/icon/ico_place_ev_station@4x.png';
import IconCafe from 'resource/pubImages/icon/ico_place_cafe@4x.png';
import IconTour from 'resource/pubImages/icon/ico_place_culture_solid@4x.png';
import IconConvenience from 'resource/pubImages/icon/ico_place_convenience@4x.png';
import IconBank from 'resource/pubImages/icon/ico_place_bank@4x.png';

export const NO_EXPOSE_TIME = {
  start: '2000-01-01 00:00:00',
  end: '2000-01-01 00:00:00',
};

export const DEFAULT_VALID_CATEGORY_PAGE: TCategoryPageProperty[] = [
  {
    type: EPlaceCategoryType.POPULAR,
    title: '티맵인기',
    sort: {
      items: [ESortOption.DISTANCE],
      default: ESortOption.DISTANCE,
    },
  },
  {
    type: EPlaceCategoryType.HOTL_ALL,
    title: '주변 숙박',
    sort: {
      items: [ESortOption.DISTANCE, ESortOption.SCORE],
      default: ESortOption.SCORE,
    },
  },
  {
    type: EPlaceCategoryType.OIL_GAS_ALL,
    title: '주변 주유소',
    sort: {
      items: [ESortOption.DISTANCE, ESortOption.PRICE],
      default: ESortOption.DISTANCE,
    },
  },
  {
    type: EPlaceCategoryType.FOOD_ALL,
    title: '주변 음식점',
    sort: {
      items: [ESortOption.DISTANCE, ESortOption.SCORE],
      default: ESortOption.SCORE,
    },
  },
  {
    type: EPlaceCategoryType.PARKING_LOT,
    title: '주변 주차장',
    sort: {
      items: [ESortOption.DEST_SCORE, ESortOption.DISTANCE],
      default: ESortOption.DEST_SCORE,
    },
  },
  {
    type: EPlaceCategoryType.OIL_EV_ALL,
    title: '주변 전기차충전소',
    sort: {
      items: [ESortOption.DEST_SCORE, ESortOption.DISTANCE],
      default: ESortOption.DEST_SCORE,
    },
  },
  {
    type: EPlaceCategoryType.FOOD_CAFE,
    title: '주변 카페',
    sort: {
      items: [ESortOption.DISTANCE, ESortOption.SCORE],
      default: ESortOption.SCORE,
    },
  },
  {
    type: EPlaceCategoryType.TOUR_ALL,
    title: '주변 관광명소',
    sort: {
      items: [ESortOption.DISTANCE, ESortOption.SCORE],
      default: ESortOption.DISTANCE,
    },
  },
  {
    type: EPlaceCategoryType.CV_ALL,
    title: '주변 편의점',
    sort: {
      items: [ESortOption.DISTANCE, ESortOption.SCORE],
      default: ESortOption.DISTANCE,
    },
  },
  {
    type: EPlaceCategoryType.BANK_ALL,
    title: '주변 은행',
    sort: {
      items: [ESortOption.DISTANCE, ESortOption.SCORE],
      default: ESortOption.DISTANCE,
    },
  },
];

export const DEFAULT_VALID_CATEGORY_LINK: TCategoryLinkProperty[] = [
  {
    label: '티맵인기',
    type: EPlaceCategoryType.POPULAR,
    icon: IconPopular,
  },
  {
    label: '숙박',
    type: EPlaceCategoryType.HOTL_ALL,
    icon: 'https://poi-cdn.tmap.co.kr/static/quicksearch/ico/HOTLALL.png',
  },
  {
    label: '주유소',
    type: EPlaceCategoryType.OIL_GAS_ALL,
    icon: IconOilStation,
  },
  {
    label: '음식점',
    type: EPlaceCategoryType.FOOD_ALL,
    icon: IconRestaurant,
  },
  {
    label: '주차장',
    type: EPlaceCategoryType.PARKING_LOT,
    icon: IconParking,
  },
  {
    label: '전기차충전소',
    type: EPlaceCategoryType.OIL_EV_ALL,
    icon: IconEV,
  },
  {
    label: '카페',
    type: EPlaceCategoryType.FOOD_CAFE,
    icon: IconCafe,
  },
  {
    label: '관광명소',
    type: EPlaceCategoryType.TOUR_ALL,
    icon: IconTour,
  },
  {
    label: '편의점',
    type: EPlaceCategoryType.CV_ALL,
    icon: IconConvenience,
  },
  {
    label: '은행',
    type: EPlaceCategoryType.BANK_ALL,
    icon: IconBank,
  },
];

export const getDefaultValidCategoryLink = (type) => {
  return DEFAULT_VALID_CATEGORY_LINK.find((i) => i.type === type);
};

export const getDefaultValidCategoryPage = (type) => {
  return DEFAULT_VALID_CATEGORY_PAGE.find((i) => i.type === type);
};

export const TNOW_MAIN_QUICK_LINK = [
  EPlaceCategoryType.POPULAR,
  EPlaceCategoryType.OIL_GAS_ALL,
  EPlaceCategoryType.FOOD_ALL,
  EPlaceCategoryType.PARKING_LOT,
  EPlaceCategoryType.OIL_EV_ALL,
  EPlaceCategoryType.FOOD_CAFE,
  EPlaceCategoryType.TOUR_ALL,
  EPlaceCategoryType.CV_ALL,
  EPlaceCategoryType.BANK_ALL,
].map((v) => getDefaultValidCategoryLink(v));

export const SEARCH_MAIN_QUICK_LINK = [
  EPlaceCategoryType.POPULAR,
  EPlaceCategoryType.HOTL_ALL,
  EPlaceCategoryType.OIL_GAS_ALL,
  EPlaceCategoryType.FOOD_ALL,
  EPlaceCategoryType.PARKING_LOT,
  EPlaceCategoryType.OIL_EV_ALL,
  EPlaceCategoryType.FOOD_CAFE,
  EPlaceCategoryType.TOUR_ALL,
  EPlaceCategoryType.CV_ALL,
  EPlaceCategoryType.BANK_ALL,
].map((v) => getDefaultValidCategoryLink(v));

export const SEARCH_RESULT_QUICK_LINK = [
  EPlaceCategoryType.POPULAR,
  EPlaceCategoryType.PARKING_LOT,
  EPlaceCategoryType.OIL_EV_ALL,
].map((v) => getDefaultValidCategoryLink(v));

// https://tmobi.atlassian.net/wiki/spaces/TMAPBIZ/pages/688947340/v1
const DEFAULT_CATEGORY_TIMETABLE = [
  {start: 10, end: 13, category: EPlaceCategoryType.FOOD_ALL},
  {start: 13, end: 16, category: EPlaceCategoryType.FOOD_CAFE},
  {start: 16, end: 20, category: EPlaceCategoryType.FOOD_ALL},
];

const WEEKEND_CATEGORY_TIMETABLE = [
  ...DEFAULT_CATEGORY_TIMETABLE,
  {start: 8, end: 10, category: EPlaceCategoryType.TOUR_ALL},
];

const categoryList = IS_WEEKEND ? WEEKEND_CATEGORY_TIMETABLE : DEFAULT_CATEGORY_TIMETABLE;

export const TNOW_DEFAULT_CATEGORY =
  categoryList.find((v) => isInHourRange(v))?.category || EPlaceCategoryType.FOOD_ALL;
