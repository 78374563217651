import {useState, useCallback, useMemo, useEffect} from 'react';

import {EDateFormat} from 'types/DateTime';
import {SEOUL_BOUNDS, BUSAN_BOUNDS} from 'constant/Map';

import {checkInsideBoundsBox} from 'utils/map';
import {getSafeDateFormat} from 'utils/date';
import {getDefaultApiStatus} from 'utils/apis';
import {useAppSelector} from 'ducks/hooks';
import {fetchSearchRecommendList} from 'ducks/tnow/slice';
import {useSearchPageVisible} from 'hooks/useSearchPageVisible';
import {TSearchRecommend} from 'ducks/search/types';
import {useOnce} from './useOnce';
import {ESortOption} from 'types/Search';
import {TLonLat} from 'types/Map';

const MAIN_CITY_BOUNDS = [SEOUL_BOUNDS, BUSAN_BOUNDS];

const MAIN_CITY_RADIUS = 1000;
const SUB_CITY_RADIUS = 3000;

const EXPOSE_START_TIME = 11;
const EXPOSE_END_TIME = 19;

const DEFAULT_PARAMS = {
  playEatYn: 'Y',
  page: 1,
  size: 5,
};

const getIsExposeTime = () =>
  new Date().getHours() >= EXPOSE_START_TIME && new Date().getHours() < EXPOSE_END_TIME;

export const useSearchRecommend = () => {
  const {nowCenter, mapContext, userPosition} = useAppSelector((state) => ({
    nowCenter: state.map.nowCenter,
    mapContext: state.userInfo.mapContext,
    userPosition: state.map.userPosition,
  }));
  const {showMain} = useSearchPageVisible();

  const [recommendList, setRecommendList] = useState<TSearchRecommend[]>([]);
  const [subTitle, setSubTitle] = useState<string>('');
  const [apiStatus, setApiStatus] = useState(getDefaultApiStatus({recommendList, subTitle}));
  const [isExposeTime, setIsExposeTime] = useState<boolean>(getIsExposeTime());
  const [lonLat, setLonLat] = useState<TLonLat | undefined>();

  const isMainCity = useMemo(
    () =>
      MAIN_CITY_BOUNDS.filter(
        (city) => lonLat && checkInsideBoundsBox({bounds: city, lonlat: lonLat})
      ).length > 0,
    [lonLat]
  );

  const radius = useMemo(() => (isMainCity ? MAIN_CITY_RADIUS : SUB_CITY_RADIUS), [isMainCity]);

  const getList = useCallback(async () => {
    const result = await fetchSearchRecommendList({
      ...lonLat,
      sort: isExposeTime ? ESortOption.SCORE : ESortOption.SCORE_WEEK,
      radius,
      ...DEFAULT_PARAMS,
    });

    setRecommendList(result.docs);
    result?.latestUpdateTime &&
      setSubTitle(
        isExposeTime
          ? `${getSafeDateFormat(result.latestUpdateTime, EDateFormat.HHmm)} 기준`
          : '최근 일주일 길안내 누적순'
      );
    setApiStatus((prev) => ({
      ...prev,
      loaded: true,
      loading: false,
    }));
  }, [lonLat, isExposeTime, radius]);

  useEffect(() => {
    if (!showMain || !lonLat) {
      setApiStatus((prev) => ({
        ...prev,
        loaded: true,
        loading: false,
      }));
      return;
    }

    setIsExposeTime(getIsExposeTime());
    setApiStatus((prev) => ({
      ...prev,
      loading: true,
      loaded: false,
    }));

    getList();
  }, [lonLat, showMain, getList]);

  useOnce(nowCenter, () => {
    if (nowCenter?.lon && nowCenter?.lat) {
      setLonLat(nowCenter);
      return;
    }

    if (mapContext?.x && mapContext?.y) {
      setLonLat({lon: mapContext.x, lat: mapContext.y});
      return;
    }

    setLonLat(userPosition);
    // mapContext, userPosition, nowCenter 순으로 데이터 받아옴.
    // AOS mapContext 검색메인 진입 초기에 못받아옴.
  });

  return {
    recommendList,
    subTitle,
    apiStatus,
    isExposeTime,
    radius,
    lonLat,
  };
};

export default useSearchRecommend;
