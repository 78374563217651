import {isProdEnv} from 'constant/Env';
import {getRedirectFromInParam} from 'utils/tmapUtils';

export type TRemoteConfigScope =
  | 'search_category_link_prod'
  | 'custom_resources_for_place_service_prod'
  | 'tnow_category_link_prod'
  | 'search_category_link_dev'
  | 'custom_resources_for_place_service_dev'
  | 'tnow_category_link_dev'
  | 'tnow_popup_prod'
  | 'tnow_popup_dev'
  | 'category_ev_filter_dev'
  | 'category_ev_filter_prod';

export type TRemoteConfigKeys =
  | 'THEME_MARKER'
  | 'CUSTOM_CATEGORY_PAGE'
  | 'SEARCH_MAIN_CATEGORY_LINK'
  | 'SEARCH_RESULT_CATEGORY_LINK'
  | 'TNOW_CATEGORY_LINK'
  | 'TNOW_POPUP_VERSION'
  | 'TNOW_POPUP_LIST'
  | 'EV_FILTER_LIST';

export type TPageConfig = {
  scope: TRemoteConfigScope;
  keys: TRemoteConfigKeys[];
}[];

const {isFromProdInRtg} = getRedirectFromInParam();
const isProdConfig = isFromProdInRtg || isProdEnv;

export const SEARCH_CONFIG: TPageConfig = [
  {
    scope: isProdConfig ? 'search_category_link_prod' : 'search_category_link_dev',
    keys: ['SEARCH_MAIN_CATEGORY_LINK', 'SEARCH_RESULT_CATEGORY_LINK'],
  },
  {
    scope: isProdConfig
      ? 'custom_resources_for_place_service_prod'
      : 'custom_resources_for_place_service_dev',
    keys: ['THEME_MARKER', 'CUSTOM_CATEGORY_PAGE'],
  },
];

export const PLACE_CONFIG: TPageConfig = [
  {
    scope: isProdConfig ? 'tnow_category_link_prod' : 'tnow_category_link_dev',
    keys: ['TNOW_CATEGORY_LINK'],
  },
  {
    scope: isProdConfig
      ? 'custom_resources_for_place_service_prod'
      : 'custom_resources_for_place_service_dev',
    keys: ['THEME_MARKER', 'CUSTOM_CATEGORY_PAGE'],
  },
  {
    scope: isProdEnv ? 'tnow_popup_prod' : 'tnow_popup_dev',
    keys: ['TNOW_POPUP_VERSION', 'TNOW_POPUP_LIST'],
  },
  {
    scope: isProdConfig ? 'category_ev_filter_prod' : 'category_ev_filter_dev',
    keys: ['EV_FILTER_LIST'],
  },
];

export const FIRE_BASE_WEBAPP_CONFIG = {
  apiKey: 'AIzaSyASeTN_1BEmJfEAXXIb887F0kzwuzUU0fM',
  authDomain: 't-map-474e8.firebaseapp.com',
  databaseURL: 'https://t-map-474e8.firebaseio.com',
  projectId: 't-map-474e8',
  storageBucket: 't-map-474e8.appspot.com',
  messagingSenderId: '272139553947',
  appId: '1:272139553947:web:947432ec58d39a2529ca3e',
  measurementId: 'G-CC0CSZ32JQ',
};
