import {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {useHistory} from 'react-router';
import {INVALID_SESSIONID} from '@lcc/tmap-inapp';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchTNowLinks} from 'ducks/tnow/slice';
import actions from 'ducks/actions';
import {ECarFuel} from 'ducks/userInfo/types';
import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';
import {Paths} from 'constant/RoutePath';
import {StorageKey} from 'constant/Storage';
import {EActionId, EPageType, TLA_PAGE_ID} from 'constant/Log';
import ua from 'utils/uaParser';
import {init as initLog} from 'utils/logManager';
import * as TMapInApp from 'utils/tmapInApp';
import {EListMode, TListDrawerResult} from 'types/ListDrawer';
import {getAllParams} from 'utils/apis';
import {generateUrl, moveToUrl} from 'utils/url';
import useUserData from 'hooks/useUserData';
import useTNowSearch from 'hooks/useTNowSearch';
import useLogger from 'hooks/useLogger';
import useSendPagePerformanceLog from 'hooks/useSendPagePerformanceLog';
import useSendPlacePageRefreshLog from 'hooks/useSendPlacePageRefreshLog';
import useMapOffset from 'hooks/useMapOffset';
import {filterQueryToCategoryParam} from 'hooks/useValidCategory';
import {useOnce} from 'hooks/useOnce';
import {useParamLog} from 'hooks/useParamLog';
import {useRemoteConfig} from 'hooks/useRemoteConfig';
import {getValidLonLat} from 'utils/map';
import {HistoryBackLink} from 'components/HistoryBackLink';
import {CENTER_WGS84} from 'constant/Map';
import PlaceVerticalPopup from 'components/place/PlaceVerticalPopup';
import {PLACE_CONFIG} from 'constant/Firebase';
import PlaceTagList from 'components/PlaceTagList';
import TNowListWrap from 'components/tnow/TNowListWrap';
import TNowHeader from 'components/tnow/TNowHeader';
import DrawerContainer from 'components/DrawerContainer';
import PlaceMap from 'components/PlaceMap';
import VSMCompass from 'components/VSMCompass';
import BuildInfo from 'components/BuildInfo';
import {
  TNOW_CATEGORY_AREA_HEIGHT,
  TNOW_CENTER_POINT_MIN_HEIGHT,
  TNOW_LIST_HANDLE_HEIGHT,
  TNOW_CENTER_MAP_RATIO,
  DEFAULT_HANDLE_BORDER_RADIUS,
  DEFAULT_HANDLE_SIZE,
  MAX_MARKER_WIDTH,
  MAX_MARKER_TITLE_HEIGHT,
  MAX_MARKER_HEIGHT,
} from 'constant/Size';

import {ReactComponent as IconArrow} from 'resource/images/@tmds_element/ico_arrow_left.svg';

import s from 'styles/pages/PlaceMainPage.module.scss';

const PlaceMainPage = () => {
  const dispatch = useAppDispatch();
  const {autoSearchWithPosition} = useTNowSearch();
  const {sendClickLogWithMapView, sendTNowResultLog, sendPlacePageLog} = useLogger();
  const {
    initPitch,
    initBearing,
    userPosition,
    userInteraction,
    carInfo,
    map,
    rdLayout,
    data,
    apiLoaded,
    currentPage,
    rdUserInfo,
    links,
    isLogInitialized,
    resumeKey,
    categoryType,
  } = useAppSelector((state) => ({
    initPitch: state.map.nowPitch,
    initBearing: state.map.nowBearing,
    userPosition: state.map.userPosition,
    userInteraction: state.userInteraction,
    carInfo: state.userInfo.carInfo,
    map: state.map,
    data: state.tNow.result?.data,
    apiLoaded: state.tNow.result.loaded,
    currentPage: state.tNow.result.data.currentPage,
    links: state.tNow.links,
    rdLayout: state.layout,
    rdUserInfo: state.userInfo,
    isLogInitialized: state.log.isInitialize,
    resumeKey: state.userInteraction.resumeKey,
    categoryType: state.tNow.categoryType,
  }));

  const history = useHistory();
  const params = useMemo(() => getAllParams(), []);
  const {referrer} = useParamLog();

  const refUserChangedPosition = useRef<boolean>(false);

  const [initLoad, setInitLoad] = useState(false);
  const [startPageLoad, setStartPageLoad] = useState(false);

  const {centerOffset, getBoundsPaddingAndOffset} = useMapOffset({
    offsetTop: TNOW_CATEGORY_AREA_HEIGHT,
  });

  const isStandAloneView = useMemo(() => {
    return params.tailParam.stand_alone_view === 1;
  }, [params]);

  useUserData({ignorePosition: true});
  useSendPagePerformanceLog({isLogInitialized});
  useSendPlacePageRefreshLog({isLogInitialized});
  useRemoteConfig(PLACE_CONFIG);

  useOnce(rdUserInfo.userKey && rdUserInfo.sessionId, () => {
    const deepLinkTarget = filterQueryToCategoryParam(params);

    if (deepLinkTarget?.type) {
      sendClickLogWithMapView(EActionId.DEEPLINK_OPEN, {
        category: params.category,
        reqKey: params.reqKey,
      });

      const targetPath = generateUrl(Paths.PlaceCategory, {
        ...deepLinkTarget,
        tailParam: JSON.stringify(params?.tailParam || {}),
      });

      history.replace(Paths.PlaceMain);
      moveToUrl(window.location.origin + targetPath);

      // aos 에서 deeplink가 올때마다 티지금 탭에 새로운 페이지로 history를 쌓고 있어서 history.back으로 돌아가줌
      // aos 가 기본쪽으로 0페이지에 파라미터 없는 '/app/place/main' 을 가지고 있음
      // ios 의 경우 백버튼이 없기에 상관 없지만 정상 동작하는것 확인 됨
      // replace는 혹시나 0번째 화면이라 back 이 없을때 현재 페이지를 초기화해서 새로고치도록 적용
      if (ua.isInApp && ua.isAndroid) {
        window.history.back();
      }
      return;
    }
    setStartPageLoad(true);
  });

  useOnce(resumeKey && !startPageLoad, () => {
    setStartPageLoad(true);
  });

  useOnce(startPageLoad, () => {
    initLog({
      sessionId: rdUserInfo.sessionId === INVALID_SESSIONID ? undefined : rdUserInfo.sessionId,
      accessKey: rdUserInfo.accessKey,
      sessionKey: rdUserInfo.sessionKey,
      userKey: rdUserInfo.userKey,
      deviceId: rdUserInfo.device.deviceId,
      carrierName: rdUserInfo.device.carrierName,
      pageId: TLA_PAGE_ID,
      pageType: EPageType.PLACE_MAIN,
      title: 'TMAP 티지금',
      referrer,
    });

    dispatch(actions.log.setInitialize(true));
  });

  useOnce(!userPosition && startPageLoad, () => {
    const paramLonLat = getValidLonLat({lon: params.centerLon, lat: params.centerLat});
    const mapPitch = params.tailParam.mapPitch;
    const mapBearing = params.tailParam.mapBearing;

    mapPitch && dispatch(actions.map.setPitch(mapPitch));
    mapBearing && dispatch(actions.map.setPitch(mapBearing));

    TMapInApp.getLastPosition()
      .then((position) => {
        const mapCenter = paramLonLat || position;

        dispatch(actions.map.setUserPosition(position));
        dispatch(actions.map.setNowCenter(mapCenter));
        autoSearchWithPosition(mapCenter);
      })
      .catch(() => {
        const mapCenter = paramLonLat || CENTER_WGS84;

        dispatch(actions.map.setUserPosition(CENTER_WGS84));
        dispatch(actions.map.setNowCenter(mapCenter));
        autoSearchWithPosition(mapCenter);
      });
  });

  useOnce(isLogInitialized && apiLoaded, () => {
    sendPlacePageLog();
  });

  useOnce(!initLoad && apiLoaded, () => {
    setInitLoad(true);
  });

  useEffect(() => {
    if (carInfo.fuel && map.userPosition && !links.loading && !links.loaded) {
      const {lon, lat} = map.userPosition;
      const fuelCode = carInfo.fuel || ECarFuel.GASOLINE;

      dispatch(
        fetchTNowLinks({
          fuelCode,
          lon,
          lat,
          appVersion: ua.tmapAppVersion,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, carInfo, map.userPosition, links]);

  useEffect(() => {
    if (apiLoaded && map.userPosition) {
      sendTNowResultLog();
    }
    // 페이징 시에도 tnowList 전송을 해야하므로 currentPage 포함
  }, [apiLoaded, currentPage]);

  useEffect(() => {
    // 현위치 버튼 클릭 시
    if (map.lastCachedCenter?.from === 'app') {
      refUserChangedPosition.current = false;
    }
  }, [map.lastCachedCenter]);

  const handleClickRefresh = useCallback(
    (refreshCenter) => {
      refUserChangedPosition.current = true;

      autoSearchWithPosition({
        lon: refreshCenter.lon,
        lat: refreshCenter.lat,
      });
    },
    [autoSearchWithPosition]
  );

  const handleChangeCategory = useCallback(
    (category) => {
      dispatch(actions.tNow.updateCategoryType(category));
      autoSearchWithPosition(
        refUserChangedPosition.current ? map.lastCachedCenter : map.userPosition
      );
    },
    [dispatch, autoSearchWithPosition, map.userPosition, map.lastCachedCenter]
  );

  const drawerCenterPoint = useMemo(
    () =>
      Math.max(
        parseInt(`${rdLayout.appSize.displayHeight * TNOW_CENTER_MAP_RATIO}`, 10),
        TNOW_CENTER_POINT_MIN_HEIGHT
      ),
    [rdLayout.appSize.displayHeight]
  );

  return (
    <VSMInterfaceProvider>
      <div className={s.header} style={{top: rdLayout.appSize.statusBarHeight}}>
        {isStandAloneView ? (
          <HistoryBackLink className={s.back}>
            <IconArrow />
          </HistoryBackLink>
        ) : (
          <PlaceTagList />
        )}
        {apiLoaded && <VSMCompass className={s.vsm_compass} />}
      </div>
      <DrawerContainer
        list={data.list}
        listMode={isStandAloneView ? EListMode.TOP : userInteraction.drawerMode}
        listTopAreaPadding={isStandAloneView ? 25 : undefined}
        tooltipStorageKey={StorageKey.TNOW_TOOLTIP}
        onRefresh={handleClickRefresh}
        onChangeListMode={(drawerMode) => {
          dispatch(actions.userInteraction.setInteraction({drawerMode}));
        }}
        mapComponent={(drawerProps: TListDrawerResult) => {
          const {boundOffset, boundsPadding} = getBoundsPaddingAndOffset(drawerProps, {
            headerHeight: TNOW_CATEGORY_AREA_HEIGHT,
            maxMarkerHeight: 0,
            maxMarkerWidth: MAX_MARKER_WIDTH,
            maxMarkerTitleHeight: MAX_MARKER_TITLE_HEIGHT,
          });

          return initLoad && userPosition && boundsPadding ? (
            <PlaceMap
              initPitch={initPitch}
              initBearing={initBearing}
              priorityBounds={{top: MAX_MARKER_HEIGHT}}
              boundsPadding={boundsPadding}
              list={data.list}
              markerClickToCenter={true}
              centerOffset={centerOffset}
              boundOffset={boundOffset}
              useUserPositionBound={true}
            />
          ) : (
            <></>
          );
        }}
        listHeaderComponent={
          <TNowHeader nowCategory={categoryType} onChangeCategory={handleChangeCategory} />
        }
        listComponent={<TNowListWrap />}
        isLastPage={data.isLastPage}
        drawerOptions={{
          centerHeight: drawerCenterPoint,
          topAreaHeight: TNOW_CATEGORY_AREA_HEIGHT,
          listHandleHeight: TNOW_LIST_HANDLE_HEIGHT,
        }}
        statusBarHeight={rdLayout.appSize.statusBarHeight}
        handleBorderRadius={rdLayout.appSize.isLandscape ? 0 : DEFAULT_HANDLE_BORDER_RADIUS}
        handleSize={
          rdLayout.appSize.isLandscape
            ? rdLayout.appSize.statusBarHeight + DEFAULT_HANDLE_SIZE
            : DEFAULT_HANDLE_SIZE
        }
      />
      <BuildInfo />
      {initLoad && <PlaceVerticalPopup />}
    </VSMInterfaceProvider>
  );
};

export default PlaceMainPage;
