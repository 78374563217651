import {EDateFormat, TExposeLimitTime} from 'types/DateTime';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import intervalToDuration from 'date-fns/intervalToDuration';
import ko from 'date-fns/locale/ko';

export const parseDate = (date: string | number | Date) => {
  return typeof date === 'string'
    ? parseISO(date)
    : typeof date === 'number'
    ? new Date(date)
    : date;
};

export const getSafeDateFormat = (
  date: string | number | Date,
  formatString: EDateFormat | string
) => {
  const parsedDate = parseDate(date);

  try {
    return format(parsedDate, formatString, {
      locale: ko,
    });
  } catch {
    return date || '';
  }
};

export const floorToNearestMinutes = (date, options?) => {
  if (!date) {
    return '';
  }

  const baseMinute = options?.baseMinute || 5;
  const parsedDate = parseDate(date);
  const minute = Math.floor(parsedDate.getMinutes() / baseMinute) * baseMinute;

  return parsedDate.setMinutes(minute);
};

export const getLocalLogTime = (date?: number) => format(date || Date.now(), EDateFormat.LOG);

export const getTime = (date?: number, dateFormat: EDateFormat | string = EDateFormat.GENERAL) =>
  format(date || Date.now(), dateFormat);

export const getGeneralTime = (date?: number) => getTime(date);

export const getDateString = (date?: number) => getTime(date, EDateFormat.yyyyMMdd);

export const getApiTime = (date?: number) => getTime(date, EDateFormat.API);

export {format};

const nowTime = Date.now();

export const checkExpose = (exposeMap: TExposeLimitTime) => {
  const targetMap = {
    start: exposeMap.start && parseDate(exposeMap.start).getTime(),
    end: exposeMap.end && parseDate(exposeMap.end).getTime(),
  };

  /*
  Start  End  | before now after 
    O     O   |    X    o    x
    O         |    X    o    o
          O   |    o    o    x
              |    o    o    o
  */

  if (targetMap.start && targetMap.end) {
    return targetMap.start <= nowTime && nowTime < targetMap.end;
  } else if (targetMap.start) {
    return targetMap.start <= nowTime;
  } else if (targetMap.end) {
    return nowTime < targetMap.end;
  }

  return true;
};

export const convertStringToDate = (str, formatString: EDateFormat = EDateFormat.GENERAL) => {
  if (!str) {
    return;
  }
  const date = str.slice(0, 8) + 'T' + str.slice(8);

  return getSafeDateFormat(date, formatString);
};

export const parseSeconds = (sec: number) => {
  return intervalToDuration({start: 0, end: sec * 1000});
};

export const getMinutesFromSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};

export const isInHourRange = ({start, end}) => {
  const now = new Date().getHours();

  return start <= now && now < end;
};
