import {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import useMoveToTarget, {EFromType} from 'hooks/useMoveToTarget';
import useSearchAddress from 'hooks/useSearchAddress';
import {useSearchMainTab} from 'hooks/useSearchMainTab';
import {useAppLayout} from 'hooks/useAppLayout';
import {useSearchPageVisible} from 'hooks/useSearchPageVisible';
import {EAddressMode, TAddressItem} from 'types/Search';
import {EButtonType} from 'types/Button';
import HybridBridge from 'utils/searchBar';
import {sendSearchClickLog} from 'utils/logManager';
import Header from 'components/Header';
import {ForceRefreshButton} from 'components/ForceRefreshButton';
import {ReactComponent as IconDot} from 'resource/images/@tmds_element/ico_dot_between.svg';
import {ReactComponent as IcoArrowRightBold} from 'resource/images/@tmds_element/ico_arrow_right_bold.svg';
import {ReactComponent as IcoCancel} from 'resource/images/@tmds_solid/ico_cancel_solid.svg';

import s from 'styles/components/search/SearchAddress.module.scss';

type TAddressMap = Partial<Record<EAddressMode, TAddressItem>>;

const ADDRESS_MODE_STEP = [
  EAddressMode.CATE1,
  EAddressMode.CATE2,
  EAddressMode.CATE3,
  EAddressMode.DETAIL,
];

const ADDRESS_LABEL = {
  [EAddressMode.CATE1]: '광역시/도',
  [EAddressMode.CATE2]: '시/군/구',
  [EAddressMode.CATE3]: '도로/읍/면/동',
};

const ADDRESS_TYPE = [
  {label: '도로명', isRoad: true},
  {label: '지번', isRoad: false},
];

const SearchAddress = () => {
  const {moveBack} = useSearchMainTab();
  const {moveToSearch} = useMoveToTarget({from: EFromType.ADDRESS});
  const {showMain} = useSearchPageVisible();
  const {isLandscape} = useAppLayout();

  const refDetailInput = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState('');
  const [detailQuery, setDetailQuery] = useState('');
  const [isRoad, setIsRoad] = useState<boolean>(true);
  const [addressMap, setAddressMap] = useState<TAddressMap>({});

  const {nowMode, regionId, depth} = useMemo(() => {
    const mode = ADDRESS_MODE_STEP.find((i) => !addressMap[i]) || EAddressMode.CATE1;

    return {
      depth: mode !== EAddressMode.DETAIL ? parseInt(mode, 10) : 0,
      nowMode: mode,
      regionId: addressMap[EAddressMode.CATE2]?.areaId || addressMap[EAddressMode.CATE1]?.areaId,
    };
  }, [addressMap]);

  const isDetailView = useMemo(() => {
    if (nowMode === EAddressMode.DETAIL) {
      refDetailInput.current?.focus();

      return true;
    }
    return false;
  }, [nowMode]);

  const isTileView = useMemo(() => {
    if (nowMode === EAddressMode.CATE3 && isRoad) {
      return false;
    }
    return true;
  }, [nowMode, isRoad]);

  const isLandscapeDetail = useMemo(() => {
    if (nowMode === EAddressMode.DETAIL && isLandscape) {
      return true;
    }
    return false;
  }, [isLandscape, nowMode]);

  const result = useSearchAddress({depth, regionId, isRoad});

  const updateAddressQueryToSearchBar = useCallback((newAddressMap) => {
    const q = ADDRESS_MODE_STEP.map((i) => newAddressMap[i]?.areaName)
      .filter((i) => !!i)
      .join(' ');

    setQuery(q);
  }, []);

  const handleClickItem = useCallback(
    (address, mode) => {
      const logMap = {
        [EAddressMode.CATE1]: 'list_tap.addr_depth1',
        [EAddressMode.CATE2]: 'list_tap.addr_depth2',
        [EAddressMode.CATE3]: 'list_tap.addr_depth3',
      };

      sendSearchClickLog(logMap[mode], {
        search_query: address.areaName,
        addr_type: mode === EAddressMode.CATE3 ? (isRoad ? 'road' : 'village') : null,
      });
      setAddressMap((prev) => {
        const newMap = {...prev, [mode]: address};

        updateAddressQueryToSearchBar(newMap);

        return newMap;
      });
    },
    [updateAddressQueryToSearchBar, isRoad]
  );

  const handleResetAddress = useCallback(
    (baseIndex) => {
      setAddressMap((prev) => {
        const newMap = ADDRESS_MODE_STEP.slice(0, baseIndex).reduce(
          (obj, v) => ({
            ...obj,
            [v]: prev[v],
          }),
          {}
        );

        updateAddressQueryToSearchBar(newMap);

        return newMap;
      });
    },
    [updateAddressQueryToSearchBar]
  );

  const handleClickSearch = useCallback(() => {
    const fullQuery = `${query} ${detailQuery || ''}`.trim();

    sendSearchClickLog('tap.searchbtn', {
      search_query: fullQuery,
    });
    moveToSearch(fullQuery, false, false);
  }, [detailQuery, moveToSearch, query]);

  const handleChangeInput = useCallback(
    (e) => {
      const newDetailQuery = e.target.value || '';
      setDetailQuery(newDetailQuery);
    },
    [query]
  );

  const handleSubmit = useCallback(
    (e) => {
      e?.preventDefault();
      refDetailInput.current?.blur();

      const fullQuery = `${query} ${detailQuery || ''}`.trim();

      sendSearchClickLog('ime_tap.searchbtn', {
        search_query: fullQuery,
      });
      moveToSearch(fullQuery, false, false);
    },
    [detailQuery, moveToSearch, query]
  );

  const handleClickClear = useCallback(() => {
    if (refDetailInput.current) {
      refDetailInput.current.value = '';
    }
    setDetailQuery('');
  }, []);

  useEffect(() => {
    if (!showMain) {
      return;
    }

    HybridBridge.showSearchBar(false);
    return () => {
      HybridBridge.showSearchBar(true);
    };
  }, [showMain]);

  useEffect(() => {
    return () => {
      if (showMain) {
        setQuery('');
        setIsRoad(true);
        setAddressMap({});
        setDetailQuery('');
        refDetailInput.current?.blur();
      }
    };
  }, [showMain]);

  useEffect(() => {
    return () => {
      if (nowMode === EAddressMode.DETAIL && refDetailInput.current) {
        refDetailInput.current.value = '';
      }
      if (nowMode === EAddressMode.CATE3) {
        setIsRoad(true);
      }
    };
  }, [nowMode]);

  return (
    <div
      className={classNames(s.wrap, {
        [s.detail_landscape]: isLandscapeDetail,
        [s.is_detail_view]: isDetailView,
      })}
    >
      <div className={s.header}>
        <Header
          leftButton={EButtonType.BACK}
          onGoBack={() => {
            sendSearchClickLog('tap.back');
            HybridBridge.queryInputTextNoSuggest('');
            moveBack();
          }}
          title="주소 검색"
        />
      </div>
      <div className={s.filter}>
        <p className={s.depth}>
          {[EAddressMode.CATE1, EAddressMode.CATE2, EAddressMode.CATE3].map((cate, idx, arr) => {
            const selectArea = addressMap[cate]?.areaName;

            return (
              <Fragment key={cate}>
                <button
                  className={s.label}
                  data-select={!!selectArea}
                  onClick={() => handleResetAddress(idx)}
                >
                  {selectArea || ADDRESS_LABEL[cate]}
                </button>
                {arr[idx + 1] && <IcoArrowRightBold />}
              </Fragment>
            );
          })}
        </p>

        {nowMode === EAddressMode.CATE3 && (
          <p className={s.road}>
            {ADDRESS_TYPE.map((v, idx, arr) => (
              <Fragment key={v.label}>
                <button
                  data-select={isRoad === v.isRoad}
                  onClick={() => {
                    const actionId = v.isRoad ? 'tab_tap.road' : 'tab_tap.village';

                    sendSearchClickLog(actionId);
                    setIsRoad(v.isRoad);
                  }}
                >
                  {v.label}
                </button>
                {arr[idx + 1] && <IconDot />}
              </Fragment>
            ))}
          </p>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div className={s.detail_wrap}>
          <div className={s.detail}>
            <p className={s.label}>상세주소</p>
            <div className={s.input_wrap}>
              <div className={s.action}>
                <input
                  className={classNames({
                    [s.has_content]: detailQuery !== '',
                  })}
                  placeholder="상세주소 입력"
                  defaultValue={detailQuery}
                  ref={refDetailInput}
                  onChange={handleChangeInput}
                />
                {detailQuery !== '' && (
                  <button type="button" onClick={handleClickClear}>
                    <IcoCancel />
                  </button>
                )}
              </div>
            </div>
            <div className={s.search_btn}>
              <button type="submit" onClick={handleClickSearch}>
                검색
              </button>
            </div>
          </div>
        </div>
      </form>

      <ul
        className={classNames(s.address_list, {
          [s.tile_view]: isTileView,
        })}
      >
        {result?.data?.map((r) => {
          return (
            <li key={r.areaId} className={s.item} onClick={() => handleClickItem(r, nowMode)}>
              {r.areaName}
            </li>
          );
        })}
        {nowMode === EAddressMode.CATE1 && <ForceRefreshButton />}
      </ul>
    </div>
  );
};

export default SearchAddress;
