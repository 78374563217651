export enum StorageKey {
  TNOW_TOOLTIP = 'TNOW_TOOLTIP_CLOSED',
  TNOW_CATEGORY_TOOLTIP = 'TNOW_CATEGORY_TOOLTIP',
  REFRESH_LOG_KEY = 'REFRESH_LOG_KEY',
  RECENT_QUERY = 'RECENT_QUERY',
  SEARCH_LOCATION_TYPE = 'SEARCH_LOCATION_TYPE',
  REVIEW_TOOLTIP = 'REVIEW_TOOLTIP',
  RESERVATION_WAITIING_TOOLTIP = 'WAITIING_TOOLTIP',

  TNOW_LIVE_TOOLTIP = 'TNOW_LIVE_TOOLTIP',
  TNOW_VERTICAL_POPUP = 'TNOW_VERTICAL_POPUP',

  STATUSBAR_HEIGHT = 'STATUSBAR_HEIGHT',

  // session
  FAVORITE_SORT = 'FAVORITE_SORT',
  SEARCH_RECOMMEND_POPUP_CLICKED = 'SEARCH_RECOMMEND_POPUP_CLICKED',
}

export enum ETempWindowKey {
  NOW_QUERY = '___TEMP_KEY_NOW_QUERY___',
  HISTORY_BACK_FROM_APP = '___TEMP_KEY_HISTORY_BACK_FROM_APP___',
}
