import {EMapStyle} from '@lcc/tmap-inapp';
import {MapLimitOptions} from '@vsm/vsm';
import {EMapType, TStyleSet} from 'types/Map';
import ua from 'utils/uaParser';
import {isProdEnv, isRtgEnv} from './Env';

export const TMAP_WIDTH_LEVEL_MAP = [
  {level: 5, width: 130450, base: 4891.96981025128},
  {level: 6, width: 74540, base: 2445.98490512564},
  {level: 7, width: 37270, base: 1222.99245256282},
  {level: 8, width: 18640, base: 611.49622628141},
  {level: 9, width: 9320, base: 305.7481131407048},
  {level: 10, width: 4660, base: 152.8740565703525},
  {level: 11, width: 2330, base: 76.43702828517624},
  {level: 12, width: 1160, base: 38.21851414258813},
  {level: 13, width: 582, base: 19.10925707129406},
  {level: 14, width: 291, base: 9.554628535647032},
  {level: 15, width: 145, base: 4.777314267823516},
  {level: 16, width: 72, base: 2.388657133911758},
  {level: 17, width: 36, base: 1.194328566955879},
  {level: 18, width: 18, base: 0.5971642834779395},
  {level: 19, width: 9, base: 0.2985821417389698},
];

export const TMAP_MOBILITY_WGS84 = {
  lat: 37.5652130004,
  lon: 126.9870379707,
};

// 경복궁
export const PALACE_CENTER_WGS84 = {
  lon: 126.976899873351,
  lat: 37.578072561267,
};

export const CENTER_WGS84 = isProdEnv || isRtgEnv ? TMAP_MOBILITY_WGS84 : PALACE_CENTER_WGS84;

export const DEFAULT_WGS84_LON_NUM = CENTER_WGS84.lon;
export const DEFAULT_WGS84_LAT_NUM = CENTER_WGS84.lat;

export const MAP_CONFIG_URL = {
  [EMapType.VECTOR]:
    'https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_JS.conf',
  [EMapType.RASTER]:
    'https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_RASTER_PUBLIC.conf',
};

export const MAP_SUPPORT_STYLE: Record<EMapType, Record<EMapStyle, TStyleSet>> = {
  [EMapType.VECTOR]: {
    [EMapStyle.DAY]: {
      code: 'TMAP',
      type: 'DEFAULT',
    },
    [EMapStyle.NIGHT]: {
      code: 'TMAP',
      type: 'NIGHT',
    },
    [EMapStyle.SATELLITE]: {
      code: 'SAM',
      type: 'DEFAULT',
    },
  },
  [EMapType.RASTER]: {
    [EMapStyle.DAY]: {
      code: 'TMAP_RASTER_PUBLIC',
      type: 'DEFAULT',
    },
    [EMapStyle.NIGHT]: {
      code: 'TMAP_RASTER_PUBLIC',
      type: 'DEFAULT',
    },
    [EMapStyle.SATELLITE]: {
      code: 'TMAP_RASTER_PUBLIC',
      type: 'DEFAULT',
    },
  },
};

export const DEFAULT_MIN_ZOOM = 7;
export const DEFAULT_ZOOM = 17;
export const SEARCH_DEFAULT_ZOOM = 15;

// App <-> Web 간 조정되어야 할 레벨.
// App -> Web일떼 +6. 반대는 -6
export const MAP_LEVEL_ADJUST_VALUE = ua.isIos ? 0 : 6;

export const MOVE_SPEED = 4;

export const DEFAULT_MAX_BOUNDS = {
  west: 103.68321164515379, // minLon
  south: 19.19532126570566, // minLat
  east: 155.18711791883285, // maxLon
  north: 48.49355203770695, // maxLat
};

export const MAP_LIMIT_OPTIONS: MapLimitOptions = {
  maxBounds: [
    DEFAULT_MAX_BOUNDS.west,
    DEFAULT_MAX_BOUNDS.south,
    DEFAULT_MAX_BOUNDS.east,
    DEFAULT_MAX_BOUNDS.north,
  ],
  minZoom: 4,
};

// TMAP 기본옵션
// export const MAP_LIMIT_OPTIONS: MapLimitOptions = {
//   maxBounds: [122.995927, 32.5699564, 134.045385, 43.4210035],
//   minZoom: 6,
// };

export const MARKER_LABEL_MAX = 12;

export const SEARCH_MAP_LIMIT_OPTION = {
  maxZoom: 18,
  minZoom: 6,
};

export const SEOUL_BOUNDS = [
  {lon: 127.06949064131443, lat: 37.72810873812017},
  {lon: 127.25428100214879, lat: 37.48976010579231},
  {lon: 126.89030237358227, lat: 37.345603658591855},
  {lon: 126.74215719449141, lat: 37.5784827214805},
];

export const BUSAN_BOUNDS = [
  {lat: 35.18780526024979, lon: 128.69073285015162},
  {lat: 35.42571801140513, lon: 129.21963995049424},
  {lat: 35.32905459216672, lon: 129.40651769964126},
  {lat: 34.89395050119595, lon: 128.8827494891178},
];
