import {TCategoryLinkProperty, TCategoryPageProperty} from 'ducks/category/types';
import {EEVStationType} from 'types/App';

export type TThemeMarkerConfig = {
  code: string;
  description: string;
  icon: [string, string];
  marker: [string, string];
  exposeTime: {
    start?: string;
    end?: string;
  };
};

export enum ELandingType {
  // 인앱에서 브라우저 띄우기, openServiceByUrl
  IN_APP = 'inapp',
  // 외부브라우저 띄우기, openBrowser
  EXTERNAL = 'external',
  // ex) tmap://, openBrowser
  APP_SCHEME = 'appscheme',
}

export type TTNowPopupItem = {
  type: ELandingType;
  imagePath: string;
  landingUrl: string;
  exposeTime: {
    start: string;
    end: string;
  };
};

export type TEvFilterListItem = {
  id: EEVStationType;
  label: string;
};

export type TRemoteConfig = {
  lastUpdateTime?: number;
  rawRemoteData?: Record<string, any>;

  tnowCategoryLink: TCategoryLinkProperty[];
  searchMainCategoryLink: TCategoryLinkProperty[];
  searchResultCategoryLink: TCategoryLinkProperty[];

  customCategoryPage: TCategoryPageProperty[];
  themeMarker: TThemeMarkerConfig[];

  tnowPopupVersion?: number;
  tnowPopupList: TTNowPopupItem[];

  evFilterList: TEvFilterListItem[];
};
