export const TNOW_LIST_HANDLE_HEIGHT = 46 + 20;
export const TNOW_CATEGORY_AREA_HEIGHT = 46;
export const TNOW_CENTER_POINT_MIN_HEIGHT = 192;
export const TNOW_CENTER_MAP_RATIO = 0.3;

export const LANDSCAPE_LIST_WIDTH = 360;
export const LANDSCAPE_LIST_MARGIN = 10;

export const CATEGORY_FILTER_TOP_AREA = 107;
export const CATEGORY_NO_FILTER_TOP_AREA = 57;

export const SEARCH_RESULT_LIST_HANDLE_HEIGHT = 70;
export const SEARCH_RESULT_LIST_HANDLE_HEIGHT_LANDSCAPE = 41; // 40 + line 1
export const SEARCH_RESULT_CENTER_MAP_PERCENT_SIZE = 30;
export const SEARCH_RESULT_BANNER_SIZE = 56;

export const TITLE_AREA_HEIGHT = 56;

export const MAX_MARKER_WIDTH = 170 / 2;
export const MAX_MARKER_HEIGHT = 53; // 48 + padding 5
export const MAX_MARKER_TITLE_HEIGHT = 20; // 15 + padding 5
export const MAX_MARKER_DOUBLE_TITLE_HEIGHT = 35; // 30 + padding 5

export const TO_TOP_SHOW_SCROLL_HEIGHT = 50;

export const HEADER_QUICK_LINK_MARGIN = 6;
export const HEADER_CATEGORY_LIST_MARGIN = 8;
export const DEFAULT_HANDLE_BORDER_RADIUS = 16;
export const DEFAULT_HANDLE_SIZE = 20;
export const DEFAULT_HEADER_HEIGHT = 56;

export const NARROW_WINDOW_SIZE = 320;
export const WIDE_WINDOW_SIZE = 640;
