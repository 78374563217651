import {
  SEJONG_REGION1_NAME,
  SEJONG_REGION1_SHORTEN_NAME,
  TESLA_IMAGE_RESOURCES,
} from 'constant/Poi';
import {
  LANDSCAPE_LIST_MARGIN,
  LANDSCAPE_LIST_WIDTH,
  MAX_MARKER_HEIGHT,
  MAX_MARKER_TITLE_HEIGHT,
  MAX_MARKER_WIDTH,
} from 'constant/Size';
import {
  EEVChargerOpen,
  EPlaceTag,
  TEVImageResource,
  TEvChargerInfo,
  TPlaceTagItem,
} from 'types/App';
import {TBoundsPadding} from 'types/Map';
import {devLog} from 'utils/dev';
import {splitArraySimilarSum} from './array';

export const poiIdChecker = (poiId: string | number) => {
  const strPoiId = `${poiId}`;
  const nineChecker = new RegExp(/^99/);
  const tenChecker = new RegExp(/^[6-9]/);

  if (strPoiId.length === 10 && tenChecker.test(strPoiId)) {
    devLog('[poiIdChecker] 10자리. 6~9로시작');
    return false;
  } else if (strPoiId.length === 9 && nineChecker.test(strPoiId)) {
    devLog('[poiIdChecker] 9자리. 99로시작');
    return false;
  }

  return true;
};

const REG_EXP_SPACE = /\s/g;
const DEFAULT_CHAR_SIZE = 13;
const DEFAULT_SPACE_SIZE = 6;
const DEFAULT_EXTRA_SIZE = 6 + 12 + 12; // gap + padding-left + padding-right

type TTagOptions = {
  charSize?: number;
  spaceSize?: number;
  extra?: number;
};

export const getTagWidth = (value: string, opt?: TTagOptions) => {
  const charSize = opt?.charSize || DEFAULT_CHAR_SIZE;
  const spaceSize = opt?.spaceSize || DEFAULT_SPACE_SIZE;
  const extraSize = opt?.extra || DEFAULT_EXTRA_SIZE;

  const spaceLen = value.match(REG_EXP_SPACE)?.length || 0;

  return (value.length - spaceLen) * charSize + spaceLen * spaceSize + extraSize;
};

export const splitStringTags = (arr: string[], opt?: TTagOptions): [string[], string[]] => {
  const lenArray: number[] = arr.map((n) => getTagWidth(n, opt));

  const splitResult = splitArraySimilarSum([...lenArray].sort((a: number, b: number) => b - a));

  const arr1: string[] = [];
  const arr2: string[] = [];

  lenArray.forEach((len, i) => {
    const position = splitResult[0].indexOf(len);
    if (position >= 0) {
      arr1.push(arr[i]);
      splitResult[0].splice(position, 1);
    } else {
      arr2.push(arr[i]);
    }
  });

  return [arr1, arr2];
};

export const getRandom = (range: number) => Math.floor(Math.random() * range);

export const getPlaceTagList = (t: Partial<TPlaceTagItem>) => {
  const tagList = [
    // t.isRecommendAds && EPlaceTag.SA_TF_RECOMMEND,
    t.isPopular && EPlaceTag.POPULAR,
    t.isTmapPark && EPlaceTag.TMAP_PARKING,
    t.isTmapPayment && EPlaceTag.TMAP_PAYMENT,
    t.isEco && EPlaceTag.ECO, // 광고 > 티맵3종 > 친환경 > 주차 > 발렛
    t.isRefuelDiscountCard && EPlaceTag.REFUEL_DISCOUNT_CARD,
    t.isDayOff && EPlaceTag.DAY_OFF,
    t.nearestPoi && EPlaceTag.NEAREST,
    t.cheapestPoi && EPlaceTag.CHEAPEST,
    t.isTruckRestArea && EPlaceTag.TRUCK_REST_AREA,
    t.isParking && EPlaceTag.PARKING,
    t.isTruckGasStation && EPlaceTag.TRUCK_GAS_STATION,
    t.isDiscountTicket && EPlaceTag.DISCOUNT_PARKING_TICKET,
    !t.isDiscountTicket && t.isAutoPayment && EPlaceTag.AUTO_PAYMENT,
    t.isDiscountCoupon && EPlaceTag.DISCOUNT_COUPON,
    t.evChargerOpen === EEVChargerOpen.SEMIPUBLIC && EPlaceTag.EV_CHARGER_OPEN,
    t.isValetParking && EPlaceTag.VALET,
    t.isAdvertisement && EPlaceTag.AD_POI,
    t.isCarTuning && EPlaceTag.CAR_TUNING,
    t.isCarWash && EPlaceTag.CAR_WASH,
    t.isConvenienceStore && EPlaceTag.CONVENIENCE_STORE,
    t.isSelfStation && EPlaceTag.SELF_STATION,
    t.is24Hours && EPlaceTag.ALLDAY_HOURS,
    t.isTmapReservation && EPlaceTag.TMAP_RESERVATION,
    t.isTmapWaiting && EPlaceTag.TMAP_WAITING,
    t.isTVrestaurant && EPlaceTag.TV_RESTAURANT,
  ].filter((v) => !!v);

  return tagList as EPlaceTag[];
};

export const getBoundsPadding = ({
  isLandscape,
  mapPosition,
  listPosition,
  headerHeight,
  statusBarHeight = 0,
  searchBarHeight = 0,
  maxMarkerHeight = MAX_MARKER_HEIGHT,
  maxMarkerWidth = MAX_MARKER_WIDTH,
  maxMarkerTitleHeight = MAX_MARKER_TITLE_HEIGHT,
}): TBoundsPadding | undefined => {
  const LANDSCAPE_RIGHT_PADDING = LANDSCAPE_LIST_WIDTH + LANDSCAPE_LIST_MARGIN;

  let padding: TBoundsPadding | undefined;

  if (isLandscape) {
    padding = {
      top: headerHeight + maxMarkerHeight + statusBarHeight + searchBarHeight,
      right: LANDSCAPE_RIGHT_PADDING + maxMarkerWidth,
      bottom: maxMarkerTitleHeight,
      left: maxMarkerWidth,
    };
  } else {
    padding =
      mapPosition > 0 && listPosition > 0
        ? {
            top: mapPosition + headerHeight + maxMarkerHeight,
            right: maxMarkerWidth,
            bottom: listPosition - mapPosition + maxMarkerTitleHeight,
            left: maxMarkerWidth,
          }
        : padding;
  }

  return padding;
};

export const getAddressBySejongRule = (address) => {
  const {regionName1, regionName2, regionName3, currentArea} = address || {
    regionName1: '',
    regionName2: '',
    regionName3: '',
    currentArea: '',
  };

  if (regionName1 === SEJONG_REGION1_NAME) {
    return `${SEJONG_REGION1_SHORTEN_NAME} ${regionName3}`;
  }

  if (regionName2) {
    return `${regionName2 || ''} ${regionName3 || ''}`.trim();
  }

  return currentArea;
};

export const getBoundary = (item, {min, max}) => {
  if (min > item) {
    return min;
  }

  if (max < item) {
    return max;
  }

  return item;
};

export const getCPOImage = (id) => {
  return id ? `https://poi-cdn.tmap.co.kr/ev/operator/250x250/${id}.png` : '';
};

export const getEVStationBiImage = (
  evChargerInfo: Nullable<TEvChargerInfo>,
  isTesla: Nullable<boolean>
): TEVImageResource[] => {
  let chargers: TEVImageResource[] = [];

  if (!evChargerInfo) {
    return [];
  }

  if (isTesla) {
    chargers.push(TESLA_IMAGE_RESOURCES);
  }

  const resources: TEVImageResource[] = (evChargerInfo.operatorIds || []).map((operatorId) => {
    if (operatorId === evChargerInfo.operatorId && evChargerInfo.operatorImageUrl) {
      if (evChargerInfo.operatorImageUrl === getCPOImage(operatorId)) {
        return {
          id: operatorId,
          icon: evChargerInfo.operatorImageUrl || '',
          label: evChargerInfo.operatorName || '',
          activeMarker: '',
          poiMarker: '',
        };
      }
    }

    return {
      id: operatorId,
      icon: '',
      label: '',
      activeMarker: '',
      poiMarker: '',
    };
  });

  return [...chargers, ...resources].filter((i) => i.icon && i.label);
};

export const makePlaceOptionLabel = (options, defaultLabel) => {
  if (options.length < 2) {
    return options[0]?.label || defaultLabel;
  }

  return `${options[0].label} 외 ${options.length - 1}`;
};

export const checkValidPlaceOptions = (newItemList) => {
  const filteredList = (newItemList || []).filter((n) => !!n.id).map((n) => n.id);

  return filteredList.length > 0 ? filteredList : undefined;
};
