import qs from 'query-string';
import {ONDA_HOST, POI_HOST, URL_IMAGE_CDN} from 'constant/Path';
import {EImageSize} from 'types/Image';
import ua from './uaParser';
import TMapSender, {TServiceName} from '@lcc/tmap-inapp';
import {TPoiImageInfo} from 'types/App';
import {DEEPLINK_PROTOCOL} from 'constant/DeepLink';
import {getSafeDateFormat} from './date';
import {EDateFormat} from 'types/DateTime';
import add from 'date-fns/add';

type TOndaUrlParam = {
  poiId: number;
  tab?: 'reservation-info';
  checkIn?: string;
  checkOut?: string;
  adult?: number;
};

export const generateUrl = (path: string, param = {}, encode = true) => {
  if (!path) {
    return '';
  }

  const p = qs.stringify(param, {encode});

  return p ? `${path}?${p}` : path;
};

export const getPlaceDetailUrl = (params) => {
  return generateUrl(`${POI_HOST}/app/poi/detail`, params);
};

export const getImageUrl = (imageInfo: TPoiImageInfo, imageSize: EImageSize = EImageSize.SMALL) => {
  const viewTarget = imageSize === EImageSize.SMALL ? imageInfo.url : imageInfo.url2;

  if (viewTarget) {
    return viewTarget;
  }

  if (imageInfo.path) {
    return `${URL_IMAGE_CDN}${imageInfo.path}_${imageSize}`;
  }

  return '';
};

export const moveToUrl = (url, useStatusBarArea = false) => {
  if (ua.isInApp) {
    const {serviceName, serviceData} = parseDeepLink(url);

    if (serviceName) {
      TMapSender.openServiceByName(serviceName as TServiceName, serviceData || {});
    } else {
      TMapSender.openServiceByUrl(url, {
        callbackJS: 'console.log',
        useStatusBarArea,
      });
    }
  } else {
    window.location.href = url;
  }
};

export const parseDeepLink = (url) => {
  const parseResult = qs.parseUrl(url);

  if (parseResult.url.startsWith(DEEPLINK_PROTOCOL)) {
    return {
      serviceName: parseResult.url.replace(DEEPLINK_PROTOCOL, ''),
      serviceData: parseResult.query,
    };
  }

  return {
    serviceName: null,
    serviceData: null,
  };
};

export const getTmapOndaBookingUrl = (propertyId, params: TOndaUrlParam) => {
  if (!propertyId || !params.poiId) {
    return '';
  }

  const today = new Date();
  const checkIn = params.checkIn || getSafeDateFormat(today, EDateFormat.yyyyMMdd);
  const checkOut =
    params.checkOut ||
    getSafeDateFormat(
      add(today, {
        days: 1,
      }),
      EDateFormat.yyyyMMdd
    );
  const adult = params.adult || 2;

  return generateUrl(`${ONDA_HOST}/m/properties/${propertyId}`, {
    ...params,
    checkIn,
    checkOut,
    adult,
  });
};
