import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import actions from 'ducks/actions';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {ECategoryType} from 'ducks/search/types';
import {BottomPopupModal} from 'components/BottomPopupModal';
import {EFilter, TFilterProps} from './SearchRecommendHeader';
import {ReactComponent as IconReload} from 'resource/images/ico_reload.svg';
import {SCROLLABLE_ELEMENT} from 'components/DimmedLayer';

import s from 'styles/components/search/SearchRecommendFilterDetailPopup.module.scss';

type TProps = {
  isOpen: boolean;
  close: () => void;
  filterMap: Record<string, TFilterProps>;
  onClickConfirm?: (
    filters: {[key: string]: boolean},
    categories: ECategoryType[],
    filterLog: {[key: string]: boolean}
  ) => void;
  onClickReset?: () => void;
};

export type TFilterCategory = EFilter | ECategoryType;

const SearchRecommendFilterDetailPopup = ({
  isOpen,
  close,
  filterMap,
  onClickConfirm,
  onClickReset,
}: TProps) => {
  const dispatch = useAppDispatch();
  const {search, isLandscape, prevGuideSearchType} = useAppSelector((state) => ({
    search: state.search,
    isLandscape: state.layout.appSize.isLandscape,
    prevGuideSearchType: state.search.prevGuideSearchType,
  }));

  const [clickedFilter, setClickedFilter] = useState<Record<TFilterCategory, boolean>>(
    Object.keys(filterMap).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as Record<TFilterCategory, boolean>)
  );

  const clickedFilterLength = useMemo(
    () => Object.values(clickedFilter).filter((value) => value).length,
    [clickedFilter]
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const {tmapFamousYn, openNowYn, isWaitingReservation, poiParkYn, categories} = search;

    const appliedFilter = {
      [EFilter.TMAP_FAMOUS]: tmapFamousYn ?? false,
      [EFilter.OPEN_NOW]: openNowYn ?? false,
      [EFilter.WAITING_RESERVATION]: isWaitingReservation ?? false,
      [EFilter.POI_PARK]: poiParkYn ?? false,
    } as Record<TFilterCategory, boolean>;

    categories?.forEach((category) => {
      appliedFilter[category] = true;
    });

    setClickedFilter(appliedFilter);
  }, [isOpen, filterMap, search]);

  const handleClickReset = useCallback(() => {
    setClickedFilter(
      Object.keys(filterMap).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as Record<TFilterCategory, boolean>)
    );
    onClickReset?.();
  }, [filterMap, onClickReset]);

  const handleClickConfirm = useCallback(() => {
    dispatch(
      actions.search.updateCategories({
        categories: [],
      })
    );
    dispatch(
      actions.search.updateFilter({
        tmapFamousYn: false,
        poiParkYn: false,
        openNowYn: false,
        isWaitingReservation: false,
      })
    );
    dispatch(
      actions.search.resetList({
        guideSearchType: prevGuideSearchType,
      })
    );

    const menuCategories: ECategoryType[] = [];
    const filterParams = {};
    const filterLog = {};

    Object.keys(clickedFilter).forEach((key) => {
      if (clickedFilter[key]) {
        if (key in EFilter) {
          const {apiParam, key: logKey} = filterMap[key];

          if (!apiParam) {
            return;
          }

          filterParams[apiParam] = true;
          filterLog[logKey] = true;
        } else {
          menuCategories.push(key as ECategoryType);
        }
      }
    });

    dispatch(
      actions.search.updateFilter({
        ltrYn: true,
        fromRecommendedYn: true,
        ...filterParams,
      })
    );
    dispatch(
      actions.search.updateCategories({
        ltrYn: true,
        fromRecommendedYn: true,
        categories: menuCategories,
      })
    );
    onClickConfirm?.(filterParams, menuCategories, filterLog);
    close();
  }, [onClickConfirm, close, dispatch, clickedFilter, filterMap, prevGuideSearchType]);

  return (
    <BottomPopupModal isOpen={isOpen}>
      <div
        className={classNames(s.modal_wrap, {
          [s.is_landscape]: isLandscape,
        })}
      >
        <div className={classNames(s.content_container, SCROLLABLE_ELEMENT)}>
          <div className={s.container}>
            <div className={s.title}>부가정보</div>
            <div className={classNames(s.filter_wrap, s.top)}>
              {Object.keys(filterMap).map((key) => {
                const {label, apiParam} = filterMap[key];

                if (!apiParam) {
                  return;
                }

                const isOn = clickedFilter[key];

                return (
                  <div
                    className={classNames(s.filter, {[s.on]: isOn})}
                    onClick={() => {
                      setClickedFilter((prev) => {
                        return {...prev, [key]: !prev[key]};
                      });
                    }}
                    key={label}
                  >
                    <span className={s.text}>{label}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={s.container}>
            <div className={s.title}>업종</div>
            <div className={s.filter_wrap}>
              {Object.keys(filterMap).map((key) => {
                const {label, category} = filterMap[key];

                if (!category) {
                  return;
                }

                const isOn = clickedFilter[key];

                return (
                  <div
                    className={classNames(s.filter, {[s.on]: isOn})}
                    onClick={() => {
                      setClickedFilter((prev) => {
                        return {...prev, [key]: !prev[key]};
                      });
                    }}
                    key={label}
                  >
                    <span className={s.text}>{label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={s.bottom_area}>
          <div className={s.buttons}>
            <button className={s.reset} onClick={handleClickReset}>
              {!isLandscape && <IconReload />}
              초기화
            </button>
            <button className={s.confirm} onClick={handleClickConfirm}>
              필터 적용
              <span className={s.count}>{clickedFilterLength > 0 && clickedFilterLength}</span>
            </button>
          </div>
        </div>
      </div>
    </BottomPopupModal>
  );
};

export default SearchRecommendFilterDetailPopup;
