import {useCallback, useMemo} from 'react';
import {SEARCH_RECENT_MAX_COUNT} from 'constant/Search';
import {sendSearchClickLog} from 'utils/logManager';
import {ERecentItemType} from 'types/Search';
import {TActionId} from 'types/Log';
import {useAppSelector} from 'ducks/hooks';
import useRecentList from 'hooks/useRecentList';
import useRecentQuery from 'hooks/useRecentQuery';
import {useOnce} from 'hooks/useOnce';
import usePrevious from 'hooks/usePrevious';

import SearchRecentRecommendation from 'components/search/SearchRecentRecommendation';
import SearchRecentItem from 'components/search/SearchRecentItem';

import s from 'styles/components/search/SearchRecentList.module.scss';

const TAP_ACTION_ID_MAP: Record<ERecentItemType, TActionId> = {
  [ERecentItemType.POI]: 'tap.lastD',
  [ERecentItemType.PUBLIC_POI]: 'tap.lastT',
  [ERecentItemType.HOME]: 'tap.lastD_bookmark',
  [ERecentItemType.OFFICE]: 'tap.lastD_bookmark',
  [ERecentItemType.FAVORITE]: 'tap.lastD_bookmark',
  [ERecentItemType.FAVORITE_STATION]: 'tap.lastT_bookmark',
  [ERecentItemType.FAVORITE_SUBWAY]: 'tap.lastT_bookmark',
  [ERecentItemType.BUS_STATION]: 'tap.lastT',
  [ERecentItemType.SUBWAY]: 'tap.lastT',
  [ERecentItemType.QUERY]: 'tap.lastS',
};

const TYPE_MAP: Record<ERecentItemType, string> = {
  [ERecentItemType.POI]: 'POI',
  [ERecentItemType.PUBLIC_POI]: 'PLACE',
  [ERecentItemType.HOME]: 'home',
  [ERecentItemType.OFFICE]: 'office',
  [ERecentItemType.FAVORITE]: 'bookmark',
  [ERecentItemType.FAVORITE_STATION]: 'BUSSTATION',
  [ERecentItemType.FAVORITE_SUBWAY]: 'SUBWAY',
  [ERecentItemType.BUS_STATION]: 'BUSSTATION',
  [ERecentItemType.SUBWAY]: 'SUBWAY',
  [ERecentItemType.QUERY]: 'QUERY',
};

export const RECOMMENDATION_POSITION = 6;

export const SearchRecentList = () => {
  const {recentList: lastRecentList, recentListApiLoaded} = useRecentList({
    listLength: SEARCH_RECENT_MAX_COUNT,
  });
  const {isHybrid} = useAppSelector((state) => ({
    isHybrid: state.layout.isHybrid,
  }));
  const {updateLocalItems, getLocalItems} = useRecentQuery();
  const prevRecentList = usePrevious(lastRecentList);

  const recentList = useMemo(
    () => (recentListApiLoaded ? lastRecentList : prevRecentList),
    [lastRecentList, recentListApiLoaded, prevRecentList]
  );
  const recentListHeadSlice = useMemo(
    () => (recentList || []).slice(0, RECOMMENDATION_POSITION),
    [recentList]
  );
  const recentListTailSlice = useMemo(
    () => (recentList || []).slice(RECOMMENDATION_POSITION),
    [recentList]
  );

  const handleClickItem = useCallback((data, idx) => {
    const actionId = TAP_ACTION_ID_MAP[data.type];
    const type = TYPE_MAP[data.type];

    sendSearchClickLog(actionId, {
      pkey: data.poiInfo?.pkey || data.poiInfo?.poiId,
      search_query: data.name,
      list_seq: idx,
      type,
    });
  }, []);

  useOnce(!isHybrid, () => {
    updateLocalItems(getLocalItems());
  });

  return (
    <div className={s.wrap}>
      <ul className={s.list}>
        {recentListHeadSlice.map((data, idx) => {
          return (
            <SearchRecentItem {...data} key={data.id} onClick={() => handleClickItem(data, idx)} />
          );
        })}
        <SearchRecentRecommendation />
        {recentListTailSlice.map((data, idx) => {
          return (
            <SearchRecentItem
              {...data}
              key={data.id}
              onClick={() => handleClickItem(data, RECOMMENDATION_POSITION + idx)}
            />
          );
        })}
      </ul>
      {recentListApiLoaded && (recentList || []).length < 1 && (
        <div className={s.no_result}>최근 검색한 기록이 없습니다.</div>
      )}
    </div>
  );
};
