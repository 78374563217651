import {MouseEventHandler, useCallback, useState} from 'react';
import classNames from 'classnames';
import ImageLoader from 'components/ImageLoader';

import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';

import s from 'styles/components/@tmds/TmdsVerticalPopup.module.scss';

type TProps = {
  popupList: {
    imagePath: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
  }[];
  onHide?: MouseEventHandler<HTMLDivElement>;
  onClose?: MouseEventHandler<HTMLDivElement>;
  onClickDimmed?: MouseEventHandler<HTMLDivElement>;
  className?: string;
};

const DOT_INDICATOR_LIST = [1, 2];
const INDICATOR_IMAGE_COUNT = 2;

const TmdsVerticalPopup = ({popupList, onHide, onClose, onClickDimmed, className}: TProps) => {
  const [flickingIndex, setFlickingIndex] = useState(0);

  const FlickingComponent = useCallback(
    ({children}) =>
      popupList.length > 1 ? (
        <Flicking moveType={['snap', {count: 1}]} onChanged={(e) => setFlickingIndex(e.index)}>
          {children}
        </Flicking>
      ) : (
        children
      ),
    [popupList.length]
  );

  const handleClickDimmed = useCallback(
    (e) => {
      onClickDimmed?.(e);
    },
    [onClickDimmed]
  );

  const handleClickHide = useCallback(
    (e) => {
      e.stopPropagation();
      onHide?.(e);
    },
    [onHide]
  );

  const handleClickClose = useCallback(
    (e) => {
      e.stopPropagation();
      onClose?.(e);
    },
    [onClose]
  );

  const handleClickItem = useCallback((e, onClick) => {
    e.stopPropagation();

    onClick?.(e);
  }, []);

  if (popupList.length === 0) {
    return null;
  }

  return (
    <div className={classNames(s.wrap, className)} onClick={handleClickDimmed}>
      <div className={s.popup_wrap}>
        {popupList.length === INDICATOR_IMAGE_COUNT && (
          <div className={s.dot_indicator}>
            {DOT_INDICATOR_LIST.map((d, i) => (
              <span
                key={d}
                className={classNames(s.item, {
                  [s.is_active]: flickingIndex === i,
                })}
              />
            ))}
          </div>
        )}
        {popupList.length > INDICATOR_IMAGE_COUNT && (
          <div className={s.pagination}>
            {flickingIndex + 1} / {popupList.length}
          </div>
        )}
        <FlickingComponent>
          {popupList.map((v, i) => (
            <div key={i} className={s.item} onClick={(e) => handleClickItem(e, v.onClick)}>
              <ImageLoader src={v.imagePath} alt="팝업 이미지" toggleClass={s.fade_in} />
            </div>
          ))}
        </FlickingComponent>
      </div>
      <div className={s.button_wrap}>
        <button className={s.hide_button} onClick={handleClickHide}>
          다시보지 않기
        </button>
        <button onClick={handleClickClose}>닫기</button>
      </div>
    </div>
  );
};

export default TmdsVerticalPopup;
