import {useEffect} from 'react';
import {useAppDispatch} from 'ducks/hooks';
import {BrowserRouter as Router} from 'react-router-dom';

import actions from 'ducks/actions';
import useWindowSize from 'hooks/useWindowSize';
import renderNestedRouter from 'utils/renderNestedRouter';
import routes from './routes';

const App = () => {
  const dispatch = useAppDispatch();
  const nowWindowSize = useWindowSize();

  useEffect(() => {
    dispatch(
      actions.layout.windowResize({width: nowWindowSize.width, height: nowWindowSize.height})
    );

    if (nowWindowSize.isLandscape !== null) {
      dispatch(actions.layout.setLandscape(nowWindowSize.isLandscape));
    }
  }, [dispatch, nowWindowSize]);

  return <Router>{renderNestedRouter(routes)}</Router>;
};

export default App;
