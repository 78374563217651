import {AxiosResponse} from 'axios';
import {ECarFuel, ECarModel, TQueryItem} from '@lcc/tmap-inapp';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  API_PATH,
  EApiResponseCode,
  FRONTMAN_API_PATH,
  FULFILLED_STATE,
  PENDING_STATE,
  REJECTED_STATE,
} from 'constant/Api';
import {getDefaultApiStatus, postApi} from 'utils/apis';
import fetcher, {frontmanFetcher} from 'utils/fetcher';
import {parseFrontmanData} from 'utils/frontmanApi';
import {
  TPersonalPlace,
  TUserInfoState,
  TPersonalInfoPayload,
  TPersonalInfoProps,
  EAddressType,
  TFavoriteRoutePayload,
  TMapContext,
  EDeleteUserInfoResult,
  TYNUserSetting,
  TUserDataResponse,
  TPersonalPlaceItem,
  TPersonalPublicRecentItem,
} from './types';
import {TFavoriteRouteItem, TRecentItem} from 'types/Search';
import {StoreState} from 'ducks/store';
import {addPersonalPoiKey} from 'utils/tmapUtils';
// import {TRANS_PUBLIC_FAVORITE} from 'utils/testData';

const initialState: TUserInfoState = {
  carInfo: {model: null, fuel: null},
  personalPlace: getDefaultApiStatus<TPersonalPlace>({
    home: undefined,
    office: undefined,
    favorites: [],
    recentDestination: [],
    publicRecentDestination: [],
    favoritesPublic: [],
  }),
  favoriteRoutes: getDefaultApiStatus<TFavoriteRouteItem[]>([]),
  addressType: EAddressType.ROAD,
  recentQueries: {
    items: [],
    date: 0,
  },
  accessKey: '',
  euk: '',
  sessionKey: '',
  searchSessionKey: '',
  sessionId: '',
  adId: '',
  userKey: '',
  deviceServiceVendorId: '',
  showFavorite: false,
  showRecentlyDestination: false,
  device: {
    carrierName: '',
    deviceId: '',
  },
  name: '',
  phoneNumber: '',
  mapContext: undefined,
  sendDestinationToCar: undefined,
};

const ACTION_PREFIX = 'user';

const getUserData = (): Promise<AxiosResponse<TUserDataResponse>> =>
  frontmanFetcher.get(FRONTMAN_API_PATH.GET_USER_DATA, {
    params: {
      userKey: '$userKey',
      wgs84Yn: 'Y',
    },
  });

const addPoiKeyToList = (list = []) => {
  return (list || []).map((f) => addPersonalPoiKey(f));
};

const updateUserDataState = (state, action) => {
  const d = action.payload.data;

  state.personalPlace = {
    ...FULFILLED_STATE,
    data: {
      home: addPersonalPoiKey(d?.favorites.home),
      office: addPersonalPoiKey(d?.favorites.office),
      favorites: addPoiKeyToList(d?.favorites.docs),
      recentDestination: addPoiKeyToList(d?.recentDestination.docs),
      publicRecentDestination: addPoiKeyToList(d?.publicRecentDestination),
      favoritesPublic: addPoiKeyToList(d?.favoritesPublic),
    },
  };
};

export const fetchUserData = createAsyncThunk<TPersonalInfoPayload>(
  `${ACTION_PREFIX}/personal_poi_and_public_transport`,
  async (props) => {
    const response = await getUserData();
    const parsedResponse = parseFrontmanData(response.data);

    return parsedResponse as any;
  }
);

export const fetchFavoriteRoute = createAsyncThunk<TFavoriteRoutePayload, TPersonalInfoProps>(
  `${ACTION_PREFIX}/favorite_routes`,
  async (props) => {
    const response = await fetcher.get(API_PATH.GET_FAVORITE_ROUTE, {
      params: {
        accessKey: props.accessKey,
        userKey: props.userKey,
        sort: 'registDateTime',
      },
    });

    return response.data;
  }
);

export const deleteRecentDestination = createAsyncThunk<
  {success: boolean; message?: string},
  {items: TRecentItem[]}
>(`${ACTION_PREFIX}/delete_personal_poi`, async (props, creator) => {
  const storeState = creator.getState() as StoreState;
  const accessKey = storeState.userInfo.accessKey;
  const destinations = props.items
    .map((t) => {
      const poiInfo = t.poiInfo as TPersonalPlaceItem;

      if (!poiInfo) {
        return null;
      }
      return {
        customName: poiInfo?.customName,
        navX: poiInfo?.navX,
        navY: poiInfo?.navY,
      };
    })
    .filter((v) => !!v);

  try {
    const resp = await postApi(API_PATH.POST_DELETE_DESTINATION, {destinations, accessKey});
    const isSuccess = resp === EDeleteUserInfoResult.SUCCESS;

    return isSuccess ? {success: true} : {success: false, message: resp};
  } catch (e) {
    return {success: false};
  }
});

export const deletePublicTransRecentDestination = async ({
  transportIds,
}: {
  transportIds: string[];
}): Promise<{success: boolean; message?: string}> => {
  try {
    const resp = await frontmanFetcher.delete(FRONTMAN_API_PATH.DELETE_PUBLIC_TRANS, {
      params: {
        transportId: transportIds,
      },
      paramsSerializer: (params) => {
        const ids = params.transportId;

        return ids.map((id) => `transportId=${id}`).join('&');
      },
    });
    const isSuccess = `${resp.status}` === EApiResponseCode.OK;

    return isSuccess ? {success: true} : {success: false, message: resp.statusText};
  } catch (e) {
    return {success: false};
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAddressType: (state, action: PayloadAction<EAddressType>) => {
      state.addressType = action.payload;
    },
    setAccessKey: (state, action: PayloadAction<string>) => {
      state.accessKey = action.payload;
    },
    setEuk: (state, action: PayloadAction<string>) => {
      state.euk = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setSessionKey: (state, action: PayloadAction<string>) => {
      state.sessionKey = action.payload;
    },
    // https://tmobi.atlassian.net/browse/LCC-55
    setSearchSessionKey: (state) => {
      state.searchSessionKey = `${state.accessKey}_${Date.now()}`;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    resetSearchSessionKey: (state) => {
      state.searchSessionKey = '';
    },
    setUserKey: (state, action: PayloadAction<string>) => {
      state.userKey = action.payload;
    },
    setShowFavorite: (state, action: PayloadAction<boolean>) => {
      state.showFavorite = action.payload;
    },
    setRecentlyDestination: (state, action: PayloadAction<boolean>) => {
      state.showRecentlyDestination = action.payload;
    },
    setCarrierName: (state, action: PayloadAction<string>) => {
      state.device.carrierName = action.payload;
    },
    setDeviceId: (state, action: PayloadAction<string>) => {
      state.device.deviceId = action.payload;
    },
    setAdId: (state, action: PayloadAction<string>) => {
      state.adId = action.payload;
    },
    setDeviceServiceVendorId: (state, action: PayloadAction<string>) => {
      state.deviceServiceVendorId = action.payload;
    },
    setMapContext: (state, action: PayloadAction<TMapContext>) => {
      state.mapContext = action.payload;
    },
    setUserCarFuel: (state, action: PayloadAction<Nullable<ECarFuel>>) => {
      state.carInfo.fuel = action.payload;
    },
    setUserCarModel: (state, action: PayloadAction<ECarModel>) => {
      state.carInfo.model = action.payload;
    },
    setUserInfos: (state, action: PayloadAction<Partial<TUserInfoState>>) => {
      for (let key in state) {
        if (action.payload[key]) {
          state[key] = action.payload[key];
        }
      }
    },
    setRecentQueries: (state, action: PayloadAction<TQueryItem[]>) => {
      state.recentQueries = {
        items: action.payload,
        date: Date.now(),
      };
    },
    setSendDestinationToCar: (state, action: PayloadAction<TYNUserSetting>) => {
      state.sendDestinationToCar = action.payload;
    },
    updatePublicTransFavorite: (state, action: PayloadAction<TPersonalPublicRecentItem[]>) => {
      const d = state.personalPlace.data;

      if (d) {
        state.personalPlace.data = {
          ...d,
          favoritesPublic: action.payload,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state: TUserInfoState) => {
        state.personalPlace = {...initialState.personalPlace, ...PENDING_STATE};
      })
      .addCase(fetchUserData.fulfilled, updateUserDataState)
      .addCase(fetchUserData.rejected, (state, action) => {
        state.personalPlace = {
          ...initialState.personalPlace,
          ...REJECTED_STATE,
          error: action.error,
        };
      })
      .addCase(fetchFavoriteRoute.pending, (state: TUserInfoState) => {
        state.favoriteRoutes = {...initialState.favoriteRoutes, ...PENDING_STATE};
      })
      .addCase(fetchFavoriteRoute.fulfilled, (state, action) => {
        state.favoriteRoutes = {...FULFILLED_STATE, data: action.payload.data?.docs || []};
      })
      .addCase(fetchFavoriteRoute.rejected, (state, action) => {
        state.favoriteRoutes = {...initialState.favoriteRoutes, ...REJECTED_STATE};
      });
  },
});

export default userSlice;
